import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import AuthContext, {
  CompanyDetails,
  UserDetails,
} from "../context/AuthContext";
import { Cookies } from "react-cookie-consent";
import { useFetchQuery } from "../Hooks/reactQuery/useFetchQuery";
import { ClearUserDetails } from "./utils";

function OpenRoutes() {
  const ctxAuth = useContext(AuthContext);

  const { data, refetch, isError, error, isSuccess } = useFetchQuery(
    "token/validate-access-token/",
    "token-validation",
    false,
    false,
    Cookies.get("tokenAccess")
  );

  useEffect(() => {
    if (!ctxAuth.userDetails.isLoggedIn) {
      if (Cookies.get("tokenAccess")) {
        if (isSuccess) {
          const resStatus = data?.status;

          if (resStatus === "success") {
            const dataRes = data?.data;
            if (dataRes?.email.length > 0) {
              const backDetails: CompanyDetails = {
                companyName: dataRes.company_name as string,
                tokenAccess: Cookies.get("tokenAccess") as string,
                tokenRefresh: Cookies.get("tokenAccess") as string,
                referenceNumber: dataRes.reference_number as string,
                company_kyc_form_signed:
                  dataRes.company_kyc_form_signed as boolean,
                isBannedCompany: dataRes.company_banned as boolean,
                documentsVerified: dataRes.documents_verified as boolean,
              };

              const userDetails: UserDetails = {
                email: dataRes.email as string,
                name: dataRes.name as string,
                isLoggedIn: true,
                kyc_information_added: dataRes.kyc_information_added as boolean,
                hasCompany: dataRes.has_company as boolean,
                isMainUser: dataRes.is_main_user as boolean,
                otp_enabled: dataRes.otp_enabled as boolean,
                otp_verified: dataRes.otp_verified as boolean,
                otp_validated: dataRes.otp_validated as boolean,
                otp_email_enabled: dataRes.otp_email_enabled as boolean,
                otp_email_verified: dataRes.otp_email_verified as boolean,
                primary_2fa_method: dataRes.primary_2fa_method as string,
                roles: dataRes?.roles ? (dataRes.roles as string[]) : [],
              };

              ctxAuth.setCompany(backDetails);
              ctxAuth.setUser(userDetails);
            } else {
              ClearUserDetails(ctxAuth);
            }
          }
          if (resStatus === "error") {
            ClearUserDetails(ctxAuth);
          }
        } else if (isError) {
          ClearUserDetails(ctxAuth);
        } else {
          refetch();
        }
      }
    } else {
      if (Cookies.get("tokenAccess") === undefined) {
        ClearUserDetails(ctxAuth);
        ctxAuth.logout();
      }
    }
  }, [
    data,
    error,
    isError,
    isSuccess,
    ctxAuth.userDetails.isLoggedIn,
    Cookies.get("tokenAccess"),
  ]);

  return <Outlet />;
}

export default OpenRoutes;
