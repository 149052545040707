import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";

import {
  Alert,
  Box,
  Button,
  FormGroup,
  Typography,
  Grid,
  Stack,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Modal,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useNavigate } from "react-router-dom";
import { Companystyles } from "../../../theme/Style";
import {
  NGODetailsInterface,
  NGODocumentsInterface,
  NGOValues,
  SignatoryInterface,
} from "../../../interface/NGO";

// RESOUCES
import AuthContext from "../../../context/AuthContext";
import { useUpdateUserConsent } from "../../../Hooks/updateUserConsent";
import NGODetails from "../../../components/kyc/NGOs/ngoDetails";
import NGODocuments from "../../../components/kyc/NGOs/ngoFIles";
import Signatories from "../../../components/kyc/NGOs/signatories";
import { useCreateNGO } from "../../../Hooks/createNGO";
import { useCreateSignatories } from "../../../Hooks/createSignatories";

type NGOProps = {
  business_type: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "90vh",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 0,
};
export default function NGOs({ business_type }: NGOProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { responseUser, updateUser } = useUpdateUserConsent();
  const [response, NGOID, newNGO, isLoading] = useCreateNGO();
  const [responseSignatory, newSignatory, isLoading2] = useCreateSignatories();
  const [companyDetailsIcon, setCompanyDetailsIconVar] = useState(false);
  const [companyNGOIcon, setNGODocumentsIconVar] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  //create a shareHolder Documents Icon state
  const [signatoriesIcon, setSignatoriesIcon] = useState(false);

  //  const [countriesArray, callCountries] = useCountries();
  const date = new Date();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const sizeMatch = matches ? true : false;

  const todayDate = `${date.getFullYear()}-${
    date.getMonth() + 1 < 10 ? "0" : ""
  }${date.getMonth() + 1}-${date.getDate() - 1 < 10 ? "0" : ""}${
    date.getDate() - 1
  }`;

  const [formValues, setFormValues] = useState<NGOValues | any>({
    certificate_of_registration: null,
    constitution_rules_memo_articles: null,
    kra_cert: null,
    proof_of_address: null,
    resolution_to_transact: null,

    kra_pin: "",
    company_name: "",
    company_website: "",

    company_registration: "",
    company_telephone_number: "",
    company_reg_date: todayDate,

    industry: business_type,

    billing_address: "",
    P_O_BOX: "",

    number_of_employees: "0-10",
    country_of_ops: 0,
    nature_of_business: "",

    signatoryObj: {},
  });

  const [consent, setConsent] = useState({
    marketing_consent: false,
    user_consent: false,
  });

  const [mess, setMess] = useState("");
  const [btnMess, setBtnMess] = useState("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    updateUser(consent);
  };

  useEffect(() => {
    if (mess.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [mess]);

  //check for user consent
  useEffect(() => {
    if (responseUser.message === "User consent updated") {
      newNGO(formValues);
    } else if (
      responseUser.message === "Error updating user contact us for help"
    ) {
      setMess(
        "Error updating user consent,try again or contact us for support"
      );
    }
  }, [responseUser]);

  //Checks for uploaded company
  useEffect(() => {
    if (response === "ngo added") {
      newSignatory(formValues, NGOID);
    } else if (response.length > 0 && response !== "ngo added") {
      window.scrollTo(0, 0);
      setMess(
        response ||
          "Organization could not be created,try again or contact us for support"
      );
    }
  }, [response]);

  //Checks for uploaded directors
  useEffect(() => {
    if (responseSignatory === "signatory added") {
      authCtx.setUser({ ...authCtx.userDetails, hasCompany: true });
      navigate("/kyc-documents/ngo-document");
    } else if (responseSignatory === "error signatories") {
      window.scrollTo(0, 0);
      setMess(
        "There was a problem with uploading signatoires,try again or contact us for support"
      );
    }
  }, [responseSignatory]);

  const isValid = useMemo(() => {
    if (!companyDetailsIcon) {
      setBtnMess(
        "Please fill all fields in the organization details section correctly."
      );
      return false;
    }

    if (!companyNGOIcon) {
      setBtnMess(
        "Please upload all the requried documents on the document section."
      );
      return false;
    }

    if (!signatoriesIcon) {
      setBtnMess("Please fill all fields in the signatory section correctly.");
      return false;
    }

    return true;
  }, [companyNGOIcon, companyDetailsIcon, signatoriesIcon]);

  const setCompanyDetails = useCallback((e: NGODetailsInterface) => {
    //get each item in the object and add it to formvalues
    setFormValues((prev: NGOValues) => {
      return {
        ...prev,
        ...e,
      };
    });
  }, []);

  const setNGODocuments = useCallback((e: NGODocumentsInterface) => {
    setFormValues((prev: NGOValues) => {
      return {
        ...prev,
        ...e,
      };
    });
  }, []);

  const setSignatories = useCallback((e: SignatoryInterface) => {
    setFormValues((prev: NGOValues) => {
      return {
        ...prev,
        ...e,
      };
    });
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <embed
            src={
              "https://storage.googleapis.com/eu.artifacts.bonto-frontend-prod.appspot.com/bonto_static/20250219%20Sample%20board%20resolution.pdf"
            }
            width="100%"
            height="100%"
            title="Arcnet.io video"
          />
        </Box>
      </Modal>
      <Grid container py={2}>
        {mess ? (
          <Grid item xs={12}>
            <Alert severity="error">{mess}</Alert>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <Accordion
              defaultExpanded={true}
              style={{ backgroundColor: "transparent" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: `#425FB4` }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: `#F5F5F5` }}
              >
                {companyDetailsIcon ? (
                  <CheckCircleIcon
                    sx={{ color: `#425FB4`, marginRight: 2 }}
                    fontSize="small"
                    data-cy="companyCircle"
                  />
                ) : null}
                <Typography variant="body2" sx={{ color: `#425FB4` }}>
                  Organization details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <NGODetails
                  getCompanyDetailsIcon={setCompanyDetailsIconVar}
                  getFormValues={setCompanyDetails}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              defaultExpanded={true}
              style={{ backgroundColor: "transparent" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: `#425FB4` }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: `#F5F5F5` }}
              >
                {companyNGOIcon ? (
                  <CheckCircleIcon
                    sx={{ color: `#425FB4`, marginRight: 2 }}
                    fontSize="small"
                    data-cy={"companyDocumentsCircle"}
                  />
                ) : null}
                <Typography variant="body2" sx={{ color: `#425FB4` }}>
                  Organization documents
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup row={sizeMatch} sx={{ width: "100%" }}>
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Typography fontSize="14px" mb={2}>
                      You can upload the respective only documents in PDF
                      format. Here is a{" "}
                      <span
                        onClick={() => {
                          setOpen(true);
                        }}
                        style={{
                          color: "#425FB4",
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        template for the board resolution
                      </span>
                    </Typography>

                    <NGODocuments
                      getNGODocuments={setNGODocuments}
                      getNGODocumentsStatus={setNGODocumentsIconVar}
                    />
                  </Stack>
                </FormGroup>
              </AccordionDetails>
            </Accordion>

            <Accordion
              defaultExpanded={true}
              style={{ backgroundColor: "transparent" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: `#425FB4` }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: "#F5F5F5" }}
              >
                {signatoriesIcon ? (
                  <CheckCircleIcon
                    sx={{ color: `#425FB4`, marginRight: 2 }}
                    fontSize="small"
                  />
                ) : null}
                <Typography variant="body2" sx={{ color: `#425FB4` }}>
                  Organization Structure
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Signatories
                  getSignatories={setSignatories}
                  getSingatoryIconStatus={setSignatoriesIcon}
                />
              </AccordionDetails>
            </Accordion>

            <Typography mt={2} mb={1} fontSize="14px">
              These documents are required for KYC by Bonto Kenya Money Transfer
              Limited to fulfill their obligations towards the Central Bank of
              Kenya. By clicking “Submit”, you agree for Bonto Limited to share
              this data with Bonto Kenya Money Transfer Limited.
            </Typography>

            <Box
              sx={{
                paddingY: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="consent"
                    onChange={(e) =>
                      setConsent({
                        ...consent,
                        user_consent: e.target.checked,
                      })
                    }
                  />
                }
                label={
                  <Typography fontSize="14px">
                    I confirm that the information provided is accurate and
                    true. By checking this box, I consent to the use of my data
                    by Bonto Kenya Money Transfer Limited for transaction
                    purposes.
                  </Typography>
                }
              />
            </Box>

            <Box
              sx={{
                paddingY: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="marketing_consent"
                    onChange={(e) =>
                      setConsent({
                        ...consent,
                        marketing_consent: e.target.checked,
                      })
                    }
                  />
                }
                label={
                  <Typography fontSize="14px">
                    I agree to receive occasional marketing messages containing
                    information about the Company's products and services. I
                    understand that I can opt out of these communications at any
                    time by contacting Bonto Kenya Money Transfer Limited
                    directly.
                  </Typography>
                }
              />
            </Box>

            <FormGroup row style={Companystyles.groupsB}>
              <Button
                variant="contained"
                type="submit"
                sx={{ width: "100%", mt: 2 }}
                disabled={
                  !isValid || isLoading || isLoading2 || !consent.user_consent
                }
              >
                {isLoading || isLoading2 ? (
                  <Stack sx={{ color: "primary" }}>
                    <CircularProgress size={20} color="inherit" />
                  </Stack>
                ) : (
                  "   Submit"
                )}
              </Button>

              {!isValid && (
                <Alert sx={{ width: "100%", mt: 2 }} severity="error">
                  Why is the button disabled? {btnMess}
                </Alert>
              )}
            </FormGroup>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
