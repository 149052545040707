import React, { CSSProperties, useContext, useEffect } from "react";

// MATERIAL UI
import Paper from "@mui/material/Paper";
import { makeStyles } from "tss-react/mui";
import { Container } from "@mui/system";

// IMAGES
import BackgroundImageSvg from "../../resources/backgroundImages/background-image.svg";

// COMPONENTS
import Footer from "../../components/Footer";
import Navbar from "../../components/navs/Navbar";
import AuthContext from "../../context/AuthContext";
import NavbarMain from "../../components/navs/NavbarMain";

const useStyles = makeStyles<void, "paperContainerht">()(
  (_theme, _params, classes) => ({
    paperContainerht: {
      [_theme.breakpoints.down("xl")]: {
        minHeight: "100vh !important",
      },
      [_theme.breakpoints.up("xl")]: {
        minHeight: "93.2vh !important",
      },
    },
  })
);

interface IPageLayoutProps {
  showFooter?: Boolean;
  showNavbar?: Boolean;
  children?: JSX.Element | JSX.Element[];
  contentStyling?: CSSProperties;
  showBackgroundImage?: Boolean;
  CustomNavbar?: null | JSX.Element;
}

export default function PageLayout({
  showFooter = true,
  showNavbar = true,
  children,
  showBackgroundImage = true,
  contentStyling,
  CustomNavbar = null,
}: IPageLayoutProps) {
  const { classes } = useStyles();
  const authCtx = useContext(AuthContext);

  const styles = {
    paperContainer: {
      backgroundImage: showBackgroundImage
        ? `url(${BackgroundImageSvg})`
        : `linear-gradient(120deg, #E6EAF5 0%, #F0E8EE 33%, #ECE9F4 61%, #E9ECF3 100%)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100%",
      minHeight: "100vh",
    },
    paperContainerMobile: {
      paddingTop: "16px",
      borderRadius: "1px",
      boxShadow: "none",
    },
    myDiv: {
      display: "flex",
      flexFlow: "column",
      height: "100%",
      minHeight: "100vh",
      position: "relative",
    },
  };

  const renderNavbarCompnent = (): JSX.Element => {
    if (CustomNavbar) {
      return CustomNavbar;
    } else {
      return <Navbar />;
    }
  };

  return (
    <div style={styles.myDiv as CSSProperties}>
      <Paper
        elevation={0}
        style={styles.paperContainer}
        className={classes.paperContainerht}
      >
        <Container fixed style={{ ...(styles.myDiv as CSSProperties) }}>
          {showNavbar && authCtx.navstatus ? (
            <NavbarMain />
          ) : (
            renderNavbarCompnent()
          )}
          <div style={{ margin: "auto 2rem", ...contentStyling }}>
            {children}
          </div>
          {showFooter && <Footer />}
        </Container>
      </Paper>
    </div>
  );
}
