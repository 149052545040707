import { useState, useEffect, useCallback, useContext } from "react";
import AuthContext from "../context/AuthContext";
import { usePutQuery } from "./reactQuery/usePutQuery";

export const useUpdateUserConsent = () => {
  const authctx = useContext(AuthContext);

  const { mutate, data, isLoading } = usePutQuery(
    "user-profile",
    authctx.companyDetails.tokenAccess
  );

  const [responseUser, setResponse] = useState({
    message: "",
    messType: "",
  });

  const updateUser = useCallback(
    (formValues: { user_consent: boolean; marketing_consent: boolean }) => {
      const formData = new FormData();

      let user = {
        user_consent: formValues.user_consent,
        marketing_consent: formValues.marketing_consent,
      };

      formData.append("user", JSON.stringify(user));
      mutate(formData);
    },
    []
  );

  useEffect(() => {
    const resStatus: any = data?.data.status;

    if (resStatus === "success") {
      const userObject = data?.data?.data.user;

      if (userObject.user_consent) {
        setResponse({
          message: "User consent updated",
          messType: "success",
        });
      } else {
        setResponse({
          message: "Error updating user contact us for help",
          messType: "success",
        });
      }
    }
    if (resStatus === "error") {
      setResponse({
        message: "Error updating user contact us for help",
        messType: "error",
      });
    }
  }, [data]);

  return { responseUser, updateUser, isLoading } as const;
};
