import { useState, useEffect, useMemo } from "react";

import { Box, Button, FormGroup, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { SignatoryInterface } from "../../../interface/NGO";
import NGOSignatories from "../../NGOSignatories";

type Props = {
  getSignatories: (e: SignatoryInterface) => void;
  getSingatoryIconStatus: (e: boolean) => void;
};

export default function Signatories({
  getSignatories,
  getSingatoryIconStatus,
}: Props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const sizeMatch = matches ? true : false;

  const [formValues, setFormValues] = useState<SignatoryInterface>({
    signatoryObj: {},
  });

  const signatoryAdd = (e: any) => {
    setFormValues((prev: SignatoryInterface) => {
      return {
        ...prev,
        signatoryObj: { ...prev.signatoryObj, [e.htmlid]: e },
      };
    });
  };

  const [counterShareholder, changeCounterShareholder] = useState(1);

  const [holdernumber, setholdernumber] = useState([
    <NGOSignatories key={1} id={1} addSignatory={signatoryAdd} />,
  ]);

  const unifiedCounter = (
    type: string,
    change: any,
    minItems: number,
    maxItems: number
  ) => {
    if (type === "sub") {
      change((prev: any) => {
        if (prev !== minItems) return --prev;
        return prev;
      });
    } else {
      change((prev: any) => {
        if (prev < maxItems) return ++prev;
        return prev;
      });
    }
  };

  useEffect(() => {
    if (holdernumber.length < counterShareholder) {
      setholdernumber((prev) => [
        ...prev,
        <NGOSignatories
          key={holdernumber.length + 1}
          id={holdernumber.length + 1}
          addSignatory={signatoryAdd}
        />,
      ]);
    } else {
      if (holdernumber.length > 1) {
        //Remove item from elements
        holdernumber.pop();
        setholdernumber([...holdernumber]);

        const deletedCounter = counterShareholder + 1;

        //Remove item from formValues
        if (deletedCounter in formValues.signatoryObj) {
          const data = formValues.signatoryObj;
          delete data[deletedCounter];
          setFormValues({
            ...formValues,
            signatoryObj: data,
          });
        }
      }
    }
  }, [counterShareholder]);

  const signatoryArray = useMemo(
    () => holdernumber.map((e) => e),
    [holdernumber]
  );

  const signtoryIcon = useMemo(() => {
    //get length of shareholder object
    let signatoryTruth = false;

    if (Object.keys(formValues.signatoryObj).length > 0) {
      Object.keys(formValues.signatoryObj).map((e: string) => {
        if (
          formValues.signatoryObj[e].name.length > 0 &&
          formValues.signatoryObj[e].designation.length > 0 &&
          formValues.signatoryObj[e].nationality.length > 0 &&
          formValues.signatoryObj[e].d_o_b.length > 0 &&
          formValues.signatoryObj[e].signatory_id_number.length > 0 &&
          formValues.signatoryObj[e].signatory_id_file !== null &&
          formValues.signatoryObj[e].signatory_id_file !== undefined
        ) {
          signatoryTruth = true;
        } else {
          signatoryTruth = false;
        }
      });
    }
    return signatoryTruth;
  }, [formValues]);

  useEffect(() => {
    getSignatories(formValues);
    getSingatoryIconStatus(signtoryIcon);
  }, [formValues, getSignatories, getSingatoryIconStatus, signtoryIcon]);

  return (
    <FormGroup row={sizeMatch}>
      <Typography mt={4} fontSize="16px" fontWeight="bold">
        DIRECTORS/AUTHORIZED SIGNATORIES
      </Typography>
      <Typography mt={1} fontSize="14px" fontWeight="bold">
        An authorized signatory is an individual who has been formally
        designated by the Board of Directors or governing body to sign legal
        documents, contracts, and financial transactions on behalf of the
        organization.
      </Typography>

      {signatoryArray}

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
      >
        <Button
          variant="contained"
          sx={{ marginRight: "4px" }}
          onClick={() => unifiedCounter("add", changeCounterShareholder, 1, 10)}
          disabled={holdernumber.length === 10}
        >
          Add Another Signatory
        </Button>

        <Button
          variant="contained"
          sx={{ marginRight: "4px" }}
          onClick={() => unifiedCounter("sub", changeCounterShareholder, 1, 10)}
          disabled={holdernumber.length === 1}
        >
          Remove Signatory
        </Button>
      </Box>
    </FormGroup>
  );
}
