import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Typography,
  OutlinedInput,
  Stack,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
  TextField,
} from "@mui/material";

import { NGODetailsInterface, NGOValues } from "../../../interface/NGO";

//CONSTANT
import { COUNTRIES, employeesRange } from "../../../constants";

// INPUT COMPONENT STYLING
const styles = {
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    pr: 1,
    mb: 3,
    flex: 1,
  },
  inputConStack: {
    display: "flex",
    flexDirection: "column",
    width: { xs: "100%" },
    pr: 1,
  },
};

type Props = {
  getFormValues: (values: NGODetailsInterface) => void;
  getCompanyDetailsIcon: (value: boolean) => void;
};

const regex = /^[A-Z0-9]{11}$/;

export default function NGODetails({
  getFormValues,
  getCompanyDetailsIcon,
}: Props) {
  const date = new Date();
  const todayDate = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`;

  const [formValues, setFormValues] = useState<NGODetailsInterface>({
    kra_pin: "",
    company_name: "",
    company_website: "",

    company_registration: "",
    company_telephone_number: "",
    company_reg_date: todayDate,

    country_of_registration: "119",
    source_of_funds: "",

    billing_address: "",
    P_O_BOX: "",

    number_of_employees: "0-10",
    country_of_ops: "",
    nature_of_business: "",
  });

  const [selectedCountries, setSelectedCountries] = useState<any[]>([]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleDelete = (e: any) => {
    setSelectedCountries([
      ...selectedCountries.filter((coun: any) => coun.code !== e.code),
    ]);
  };
  useEffect(() => {
    const refinedCountries = selectedCountries.map((e: any) => e.code);
    setFormValues({
      ...formValues,
      country_of_ops: refinedCountries.toString(),
    });
  }, [selectedCountries]);

  useEffect(() => {
    getFormValues(formValues);

    getCompanyDetailsIcon(
      formValues.company_name.length > 0 &&
        formValues.kra_pin.length > 0 &&
        !!regex.test(formValues.kra_pin) &&
        formValues.company_registration.length > 0 &&
        formValues.company_telephone_number.length > 0 &&
        formValues.company_reg_date.length > 0 &&
        formValues.billing_address.length > 0 &&
        formValues.P_O_BOX.length > 0 &&
        formValues.nature_of_business.length > 0 &&
        formValues.country_of_ops.length > 0 &&
        formValues.country_of_registration.length > 0 &&
        formValues.source_of_funds.length > 0 &&
        isNaN(Date.parse(formValues.company_reg_date)) === false
    );
  }, [formValues, getFormValues, getCompanyDetailsIcon]);

  return (
    <>
      {/* Email and Password Section */}
      <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%" }}>
        <Box
          sx={{
            ...styles.inputContainer,
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Registered name of Organization{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id="outlined-company-name"
            type="text"
            size="small"
            name="company_name"
            placeholder="ABC Limited"
            onChange={handleChange}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>

        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Organization’s Registration Number{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id="outlined-company-registration-number"
            type="text"
            size="small"
            name="company_registration"
            value={formValues.company_registration}
            placeholder="PVT-ABCDEFGH"
            onChange={handleChange}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>

        <Box
          sx={{
            ...styles.inputContainer,
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Tax Identification Number
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>

          <OutlinedInput
            required
            id="outlined-company-kra-pin"
            type="text"
            size="small"
            name="kra_pin"
            error={
              formValues.kra_pin.length > 0 && !regex.test(formValues.kra_pin)
            }
            placeholder="A123456789B"
            onChange={handleChange}
            defaultValue={formValues.kra_pin}
            inputProps={{
              length: 11,
            }}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>
      </Stack>

      <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%" }}>
        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Description of NGO Activities{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id="outlined-nature-of-business"
            type="text"
            size="small"
            name="nature_of_business"
            value={formValues.nature_of_business}
            placeholder="Information and Technology"
            onChange={handleChange}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>

        <Box
          sx={{
            ...styles.inputContainer,
            maxWidth: "35%",
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            List of Countries of Operations{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>

          <Autocomplete
            id="country_of_ops"
            options={COUNTRIES}
            size="small"
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
            getOptionLabel={(option) => option.name}
            autoHighlight
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                const countryClenser = new Set([
                  ...selectedCountries,
                  newValue,
                ]);
                setSelectedCountries(Array.from(countryClenser));
              }
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.flag?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.flag?.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <ul
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
              listStyleType: "none",
            }}
          >
            {selectedCountries?.map((e: any) => (
              <Chip
                key={e.name}
                color="success"
                label={e.name}
                onDelete={() => handleDelete(e)}
                style={{ marginRight: "0.2rem" }}
              />
            ))}
          </ul>
        </Box>

        <Box
          sx={{
            ...styles.inputContainer,
            maxWidth: "35%",
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2" paddingX={2}>
            Country of Registration{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>

          <Autocomplete
            id={`country-of-registration`}
            options={COUNTRIES}
            size="small"
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
            value={COUNTRIES[Number(formValues.country_of_registration)]}
            getOptionLabel={(option) => option.name}
            autoHighlight
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                setFormValues({
                  ...formValues,
                  country_of_registration: String(
                    COUNTRIES.findIndex((x) => x.name === newValue.name)
                  ),
                });
              }
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.flag?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.flag?.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
      </Stack>

      <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%" }}>
        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Source of funds{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id="source_of_funds"
            type="text"
            size="small"
            name="source_of_funds"
            value={formValues.source_of_funds}
            placeholder="IT Hub"
            onChange={handleChange}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>
        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Date of Registration{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>

          <DatePicker
            label=""
            value={dayjs(formValues.company_reg_date)}
            defaultValue={dayjs(todayDate)}
            onChange={(newDate) => {
              const organizedDate = `${newDate?.year()}-${
                typeof newDate?.month() === "number" ? newDate?.month() + 1 : 1
              }-${newDate?.date()}`;

              setFormValues((Prev: NGOValues | any) => ({
                ...Prev,
                company_reg_date: organizedDate,
              }));
            }}
            format="DD/MM/YYYY"
            sx={{
              borderRadius: "13px",
            }}
            slotProps={{
              textField: {
                size: "small",
                helperText: "DD/MM/YYYY",
                id: "outlined-company-registration-date",
                sx: {
                  borderRadius: "13px",
                  backgroundColor: "transparent",
                },
              },
            }}
            maxDate={dayjs(todayDate)}
          />
        </Box>

        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Number of employees
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>

          <Select
            id="number_of_employees"
            name="number_of_employees"
            size="small"
            onChange={handleChange}
            value={formValues.number_of_employees}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
            MenuProps={{ disableScrollLock: true }}
          >
            {employeesRange?.map((number: string) => (
              <MenuItem key={number} value={number}>
                {number}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Stack>

      {/* Company section 2 */}
      <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%" }}>
        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Office Phone Number{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id="outlined-company-telephone-number"
            type="tel"
            size="small"
            name="company_telephone_number"
            value={formValues.company_telephone_number}
            placeholder="+254725123890"
            onChange={handleChange}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>

        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Physical Address{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id="outlined-company-physical-address"
            type="text"
            size="small"
            name="billing_address"
            placeholder="50, Ngong Road, Nairobi"
            onChange={handleChange}
            value={formValues.billing_address}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>

        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Post Office Address{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id="outlined-company-post-office-address"
            type="text"
            size="small"
            name="P_O_BOX"
            placeholder="PO BOX 12345-00100 NAIROBI"
            value={formValues.P_O_BOX}
            onChange={handleChange}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>
      </Stack>
      {/* end of section 2 */}

      {/* Company section 3 */}
      <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%" }}>
        <Box
          sx={{
            ...styles.inputContainer,
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Website{" "}
          </Typography>
          <OutlinedInput
            id="outlined-company-name"
            type="text"
            size="small"
            name="company_website"
            placeholder="www.abc.com"
            onChange={handleChange}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>
      </Stack>
      {/* Company section 3 */}
    </>
  );
}
