import { useContext, useEffect, useMemo } from "react";
import { Navigate, Outlet, useNavigate, useOutlet } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useFetchQuery } from "../../Hooks/reactQuery";
import CompanyPDFContext from "../../context/CompanyPDFContext";
import { Backdrop, CircularProgress } from "@mui/material";

const styles = {
  paperContainer: {
    backgroundImage: `linear-gradient(120deg, #E6EAF5 0%, #F0E8EE 33%, #ECE9F4 61%, #E9ECF3 100%)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    minHeight: "100vh",
  },
};

export default function SignDocs() {
  const ctxAuth = useContext(AuthContext);
  const outlet = useOutlet();

  let path = outlet?.props?.children?.props?.match?.pathname;

  // company details object
  const { data } = useFetchQuery(
    "auth/company-details/",
    "company-details",
    true,
    false,
    ctxAuth.companyDetails.tokenAccess
  );

  const KYCDocument = useMemo(() => {
    const resStatus = data?.status;

    if (resStatus === "error") {
      ctxAuth.logout();
      return <Navigate to="/login" />;
    } else if (resStatus === "success") {
      const companyData = data.data;

      if (companyData.company.industry === "NGO") {
        //check if path is /kyc-documents/ngo-document and return outlet

        if (path === "/kyc-documents/ngo-document") {
          return <Outlet />;
        }

        return <Navigate to="/kyc-documents/ngo-document" />;
      } else if (companyData.company.industry !== "NGO") {
        //check if path is /kyc-documents/company-document and return outlet

        if (path === "/kyc-documents/company-document") {
          return <Outlet />;
        }

        return <Navigate to="/kyc-documents/company-document" />;
      }
    }

    return (
      <Backdrop sx={styles.paperContainer} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }, [data, outlet, path]);

  if (!ctxAuth.userDetails.hasCompany) {
    return <Navigate to="/company-setup" />;
  }

  return KYCDocument;
}
