import { useState, useEffect, useCallback, useContext } from "react";
import { usePost } from ".";
import AuthContext from "../context/AuthContext";
import CompanyPDFContext from "../context/CompanyPDFContext";
import { NGOValues } from "../interface/NGO";

export const useCreateNGO = () => {
  const authctx = useContext(AuthContext);
  const companyDet = useContext(CompanyPDFContext);

  const [data, callApi, isLoading] = usePost(
    authctx.companyDetails.tokenAccess,
    true
  );

  const [response, setResponse] = useState("");
  const [NGOID, setNGOId] = useState("");

  const newNGO = useCallback((formValues: NGOValues | any) => {
    const formData = new FormData();
    for (const key of Object.keys(formValues)) {
      if (key !== "signatoryObj") {
        formData.append(key, formValues[key]);
        //append null to cr_12
        // formData.append("cr_12", "null");
        // formData.append("business_permit", "null");
        // formData.append("cert_of_incorporation", "null");
        
      }
    }

    callApi(formData, `auth/company-details/`);
  }, []);

  useEffect(() => {
    const resStatus = data?.status;

    if (resStatus === "success") {
      let resData = data.data;
      setResponse("ngo added");
      setNGOId(resData.company_details.uuid);

      companyDet.addCompanyDetails(resData.company_details);
    } else if (resStatus === "error") {
      setResponse(data.message);
    }
  }, [data]);

  return [response, NGOID, newNGO, isLoading] as const;
};
