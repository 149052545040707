import NavbarProcess from "../../../components/navs/NavbarProcess";
import { Alert, Box, Button, Grid, Stack, Typography } from "@mui/material";

import { PDFDownloadLink, PDFViewer, usePDF } from "@react-pdf/renderer";
import NGODocument from "../../../react-forms/NgoDocument";
import { useNavigate } from "react-router-dom";

import PageLayout from "../../Templates/PageLayout";

// RESOUCES
import CompanyPDFContext from "../../../context/CompanyPDFContext";
import AuthContext from "../../../context/AuthContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { usePost } from "../../../Hooks";
import { COUNTRIES } from "../../../constants";
import { CountriesType } from "../../../interface/Common";
import GetDirectorDetailsKYC, {
  TGetDirectorsDetailsKYCFields,
} from "../../../components/GetDirectorDetailsFormKYC";
import { API_RESOURCES } from "../../../api/send";
import { messageT } from "../../../components/ContactUs";
import { useFetchQuery } from "../../../Hooks/reactQuery/useFetchQuery";
import { toast } from "react-toastify";
import { NGODetailsType, SignatoriesType } from "../../../interface/NGO";

export default function NGOReview() {
  const docusignUrl =
    process.env.REACT_APP_NGO_KYC_POWERFORM ||
    "https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=556c2b05-1626-4322-b0bf-4c7c7c74cc0c&env=demo&acct=3fe334c5-314f-47f2-aff8-4cb092b50949&v=2";
  const companycontext = useContext(CompanyPDFContext);

  const authctx = useContext(AuthContext);
  const navigate = useNavigate();

  const [signatureRequestMessage, setSignatureRequestMessage] =
    useState<null | messageT>(null);
  const [documentSigned, setDocumentSigned] = useState<boolean>(false);

  const [
    dataDirectorsRequest,
    callApiDirectorsRequest,
    isLoadingDirectorsRequest,
  ] = usePost(authctx.companyDetails.tokenAccess, true);

  const [directorAsSig] = useState(true);
  const [directors, setDirector] = useState([]);
  const [signatoryDetails] = useState({
    directorData: -1,
    newUserData: "",
    email: "",
  });
  const [signatoryString, setSignatoryString] = useState("");
  const [, setNameForDoc] = useState("");
  const [errMessage] = useState<string>("");
  const [, setKYCFile] = useState<File | null>(null);
  const [kycBlob, setKYCBlob] = useState<Blob | null>(null);

  // company details object
  const {
    data: companyDetailsData,
    isLoading: companyDetailsIsLoading,
    isError,
  } = useFetchQuery(
    "auth/company-details/",
    "company-details",
    true,
    false,
    authctx.companyDetails.tokenAccess
  );

  const [ngoDetails, setNgoDetails] = useState<NGODetailsType>({
    uuid: "",
    kra_pin: "",
    company_name: "",
    company_website: "",
    company_registration: "",
    company_telephone_number: "",
    company_reg_date: "",
    billing_address: "",
    P_O_BOX: "",
    industry: "",
    number_of_employees: "0-10",
    country_of_ops: "0",
    nature_of_business: "",
    source_of_funds: "",
    country_of_registration: 0,
  });

  const signatoriesDetailsData: SignatoriesType[] = useMemo(() => {
    return companyDetailsData.data.signatories || [];
  }, [companyDetailsData]);

  // section for returning users who are yet to sign the KYC form
  useEffect(() => {
    const resStatus = companyDetailsData?.status;

    if (resStatus === "error") {
      toast.error("Failed getting directors");
    } else if (resStatus === "success") {
      const data = companyDetailsData.data;
      setNgoDetails({
        uuid: data?.company?.uuid,
        industry: data?.company?.industry,
        billing_address: data?.company?.billing_address,
        company_name: data?.company?.company_name,
        kra_pin: data?.company?.kra_pin,
        company_reg_date: data?.company?.company_reg_date,
        P_O_BOX: data?.company?.P_O_BOX,
        company_website: data?.company?.company_website,
        number_of_employees: data?.company?.number_of_employees,
        country_of_ops: data?.company?.country_of_ops,
        nature_of_business: data?.company?.nature_of_business,
        company_registration: data?.company?.company_registration,
        company_telephone_number: data?.company?.company_telephone_number,
        source_of_funds: data?.company?.source_of_funds,
        country_of_registration: data?.company?.country_of_registration,
      });
      companycontext.addCompanyDetails(data.company);
    }
  }, [companyDetailsData]);

  const [instance] = usePDF({
    document: (
      <NGODocument
        ngoDetails={ngoDetails}
        signatory={signatoriesDetailsData}
        authDetails={authctx}
      />
    ),
  });

  const generateFile = (blob: Blob) => {
    // not in use at the moment
    var file: File = new File([blob], "name.pdf", { type: blob?.type });
    setKYCFile(file);
  };

  // listen for blob changes and generate file
  useEffect(() => {
    if (kycBlob) {
      generateFile(kycBlob);
    }
  }, [kycBlob]);

  const countries = useMemo(() => {
    const name_list: string[] = [];

    ngoDetails.country_of_ops.split(",").map((e: string) => {
      COUNTRIES.filter((coun: CountriesType) => {
        if (coun.code === e) name_list.push(coun.name);
      });
    });

    return name_list;
  }, [ngoDetails]);

  //To be continued
  const SignatoryStrings = useMemo(() => {
    let superString = "";
    signatoriesDetailsData.map((e: SignatoriesType, idx: number) => {
      const singleholder = `&sig_name_${idx + 1}=${encodeURIComponent(
        e.name
      )}&sig_title_${idx + 1}=${encodeURIComponent(e.designation || "N/A")}&sig_nat_${
        idx + 1
      }=${encodeURIComponent(COUNTRIES[Number(e.nationality)].name)}&sig_dob_${
        idx + 1
      }=${encodeURIComponent(e.d_o_b)}&sig_id_${idx + 1}=${encodeURIComponent(
        e.signatory_id_number
      )}`;
      superString = superString + singleholder;
    });
    setSignatoryString(superString);
  }, [signatoriesDetailsData]);

  const [powerFormUrl, setPowerFormUrl] = useState<string>(
    `${docusignUrl}&Director_UserName=${encodeURIComponent(
      `${authctx.userDetails.name}`
    )}&Director_Email=${encodeURIComponent(
      `${String(ngoDetails.uuid).padStart(4, "0")}_KYC_${
        authctx.userDetails.email
      }`
    )}&ngo_name=${encodeURIComponent(
      `${ngoDetails.company_name.trim()}`
    )}&org_number=${encodeURIComponent(
      `${ngoDetails.company_registration}`
    )}&date_of_reg=${encodeURIComponent(
      `${ngoDetails.company_reg_date}`
    )}&tin=${encodeURIComponent(
      `${ngoDetails.kra_pin}`
    )}&address=${encodeURIComponent(
      `${ngoDetails.P_O_BOX} ${ngoDetails.billing_address}`
    )}&list_of_countries=${encodeURIComponent(
      `${countries}`
    )}&country_of_incorporation=${encodeURIComponent(
      `${COUNTRIES[ngoDetails.country_of_registration].name}`
    )}&industry=${encodeURIComponent(
      `${ngoDetails.industry}`
    )}&desc_og=${encodeURIComponent(
      `${ngoDetails.nature_of_business}`
    )}&source_of_funds=${encodeURIComponent(
      `${ngoDetails.source_of_funds}`
    )}&no_of_employees=${encodeURIComponent(
      `${ngoDetails.number_of_employees}`
    )}&office_phone=${encodeURIComponent(
      `${ngoDetails.company_telephone_number}`
    )}&contact_person=${encodeURIComponent(
      `${authctx.userDetails.name}`
    )}&contact_email=${encodeURIComponent(
      `${authctx.userDetails.email}`
    )}&company_website=${encodeURIComponent(
      `${ngoDetails.company_website}`
    )}&user_name=${encodeURIComponent(
      `${authctx.userDetails.name}`
    )}&user_email=${encodeURIComponent(
      `${authctx.userDetails.email}`
    )}${signatoryString}`
  );

  //Gets data when you visit accounts
  // useEffect(() => {
  //   console.log("data", data, "uuid", ngoDetails.uuid);
  //   if (data !== undefined && "fetch" in data) {
  //     const compid = `?id=${ngoDetails.uuid}`;
  //     setFormData(compid);
  //   } else if (data !== undefined && data.length > 0) {
  //     setDirector(data);
  //   }
  // }, [ngoDetails.uuid, data]);

  // useEffect(() => {
  //   if (formData?.includes("id")) refetch();
  // }, [formData]);

  useEffect(() => {
    if (directorAsSig) {
      if (directors !== undefined && directors.length > 0) {
        const directorsObject: any = directors[signatoryDetails.directorData];
        console.log(directorsObject, directors);
        setNameForDoc(
          `${directorsObject?.first_name}%20${directorsObject?.middle_name}%20${directorsObject?.last_name}`
        );
      }
    } else {
      setNameForDoc(`${signatoryDetails.newUserData.replaceAll(" ", "%20")}`);
    }
  }, [
    directorAsSig,
    signatoryDetails.newUserData,
    signatoryDetails.directorData,
    directors,
  ]);

  useEffect(() => {
    setPowerFormUrl(
      `${docusignUrl}&Director_UserName=${encodeURIComponent(
        `${authctx.userDetails.name}`
      )}&Director_Email=${encodeURIComponent(
        `${String(ngoDetails.uuid).padStart(4, "0")}_KYC_${
          authctx.userDetails.email
        }`
      )}&ngo_name=${encodeURIComponent(
        `${ngoDetails.company_name.trim()}`
      )}&org_number=${encodeURIComponent(
        `${ngoDetails.company_registration}`
      )}&date_of_reg=${encodeURIComponent(
        `${ngoDetails.company_reg_date}`
      )}&tin=${encodeURIComponent(
        `${ngoDetails.kra_pin}`
      )}&address=${encodeURIComponent(
        `${ngoDetails.P_O_BOX} ${ngoDetails.billing_address}`
      )}&list_of_countries=${encodeURIComponent(
        `${countries}`
      )}&country_of_incorporation=${encodeURIComponent(
        `${COUNTRIES[ngoDetails.country_of_registration].name}`
      )}&industry=${encodeURIComponent(
        `${ngoDetails.industry}`
      )}&desc_og=${encodeURIComponent(
        `${ngoDetails.nature_of_business}`
      )}&source_of_funds=${encodeURIComponent(
        `${ngoDetails.source_of_funds}`
      )}&no_of_employees=${encodeURIComponent(
        `${ngoDetails.number_of_employees}`
      )}&office_phone=${encodeURIComponent(
        `${ngoDetails.company_telephone_number}`
      )}&contact_person=${encodeURIComponent(
        `${authctx.userDetails.name}`
      )}&contact_email=${encodeURIComponent(
        `${authctx.userDetails.email}`
      )}&company_website=${encodeURIComponent(
        `${ngoDetails.company_website}`
      )}&user_name=${encodeURIComponent(
        `${authctx.userDetails.name}`
      )}&user_email=${encodeURIComponent(
        `${authctx.userDetails.email}`
      )}${signatoryString}`
    );
  }, [
    ngoDetails,
    countries,
    authctx.userDetails,
    docusignUrl,
    SignatoryStrings,
    signatoryString,
  ]);
  //https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=565e14a1-75bd-493f-bcda-9dfaf7ab80a6&env=demo&acct=3fe334c5-314f-47f2-aff8-4cb092b50949&v=2&Director_UserName=Glenn%20Onyango&Director_Email=86cfc932-86f1-4d19-be1a-5dfe88eebba4_KYC_glenntedd%2Bb2b-std-dev-2%40gmail.com&company_name=%0A%20%20%20%20%20%20NGO%20TEST&billing_address=Roam%20Park-National%20Park%20East%20Road&company_reg_date=2025-03-03&company_registration=NGO-TEST&kra_pin=ASDRETYUIOZ&P_O_BOX=P.O.BOX%2018284&country_of_ops=Kenya&country_of_incorporation=Kenya&industry=NGO&nature_of_business=We%20help%20children&number_of_employees=0-10&company_telephone_number=%2B254719458875&company_website=Glee%20Designs&user_name=Glenn%20Onyango&user_email=glenntedd%2Bb2b-std-dev-2%40gmail.com&sig_name_1=NGO%20Deno&sig_title_1=undefined&sig_nat_1=Kenya&sig_dob_1=2025-03-02&sig_id_1=559122-3440

  // GetDirectorsRequest submit function
  const getDirectorsRequest = (data: TGetDirectorsDetailsKYCFields) => {
    setSignatureRequestMessage(null);
    const secondarySignerUrl = `${docusignUrl}&Director_UserName=${encodeURIComponent(
      `${authctx.userDetails.name}`
    )}&Director_Email=${encodeURIComponent(
      `${String(ngoDetails.uuid).padStart(4, "0")}_KYC_${
        authctx.userDetails.email
      }`
    )}&ngo_name=${encodeURIComponent(
      `${ngoDetails.company_name.trim()}`
    )}&org_number=${encodeURIComponent(
      `${ngoDetails.company_registration}`
    )}&date_of_reg=${encodeURIComponent(
      `${ngoDetails.company_reg_date}`
    )}&tin=${encodeURIComponent(
      `${ngoDetails.kra_pin}`
    )}&address=${encodeURIComponent(
      `${ngoDetails.P_O_BOX} ${ngoDetails.billing_address}`
    )}&list_of_countries=${encodeURIComponent(
      `${countries}`
    )}&country_of_incorporation=${encodeURIComponent(
      `${COUNTRIES[ngoDetails.country_of_registration].name}`
    )}&industry=${encodeURIComponent(
      `${ngoDetails.industry}`
    )}&desc_og=${encodeURIComponent(
      `${ngoDetails.nature_of_business}`
    )}&source_of_funds=${encodeURIComponent(
      `${ngoDetails.source_of_funds}`
    )}&no_of_employees=${encodeURIComponent(
      `${ngoDetails.number_of_employees}`
    )}&office_phone=${encodeURIComponent(
      `${ngoDetails.company_telephone_number}`
    )}&contact_person=${encodeURIComponent(
      `${authctx.userDetails.name}`
    )}&contact_email=${encodeURIComponent(
      `${authctx.userDetails.email}`
    )}&company_website=${encodeURIComponent(
      `${ngoDetails.company_website}`
    )}&user_name=${encodeURIComponent(
      `${authctx.userDetails.name}`
    )}&user_email=${encodeURIComponent(
      `${authctx.userDetails.email}`
    )}${signatoryString}`;

    const formData = new FormData();
    formData.append("docusign_url", secondarySignerUrl);
    if (kycBlob) {
      formData.append(
        "kyc_document",
        kycBlob,
        `kyc-${data.email}-${data.company_id}.pdf`
      );
    }
    formData.append("company_id", data.company_id);
    formData.append("current_user", data.current_user);
    formData.append("email", data.email);
    formData.append("name", data.name);

    callApiDirectorsRequest(formData, `${API_RESOURCES.KYC_SIGNATURE_REQUEST}`);
  };

  // listen for directorsSignature request call
  useEffect(() => {
    const dataDirectorsRequestStatus = dataDirectorsRequest?.status;

    if (dataDirectorsRequestStatus === "error") {
      setSignatureRequestMessage({
        messType: "error",
        message: "Trouble sending request. Please contact support.",
      });
    }

    if (dataDirectorsRequestStatus === "success") {
      setSignatureRequestMessage({
        message: "Signature successfully sent",
        messType: "success",
      });
      setDocumentSigned(true);

      navigate("/kyc/signature-request");
    }
  }, [dataDirectorsRequest]);

  return (
    <PageLayout showBackgroundImage={false} CustomNavbar={<NavbarProcess />}>
      <Grid container mb={4}>
        <Grid item xs={12} marginY={3}>
          <Typography fontSize={18}>
            Thanks for all the company information. Here is the KYC form
            pre-filled with all your details. It needs to be signed by a
            director or an authorized signatory. By signing this form, you also
            agree for Bonto Limited to share the signed document with BKMTL.
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} p={2} height="700px">
          {companyDetailsIsLoading ? (
            <Typography>Loading...</Typography>
          ) : instance.error ? (
            <div> Error loading document</div>
          ) : instance.loading ? (
            <div>Loading document ...</div>
          ) : (
            <PDFViewer style={{ width: "100%", minHeight: "100%" }}>
              <NGODocument
                ngoDetails={ngoDetails}
                signatory={signatoriesDetailsData}
                authDetails={authctx}
              />
            </PDFViewer>
          )}
        </Grid>
        <Grid item xs={12} md={4} p={2}>
          {errMessage != null ? (
            !documentSigned ? (
              <>
                <Stack
                  direction={{ xs: "column", md: "column" }}
                  sx={{ width: "100%" }}
                  paddingY={2}
                  // paddingX={2}
                  spacing={1}
                >
                  <Typography paragraph fontWeight="bold">
                    Are you a Director or an Authorized Signatory? If so, please
                    click below to sign the form:
                  </Typography>
                  <PDFDownloadLink
                    document={
                      <NGODocument
                        ngoDetails={ngoDetails}
                        signatory={signatoriesDetailsData}
                        authDetails={authctx}
                      />
                    }
                    fileName={`Forex Partner Due Diligence Form`}
                    style={{
                      color: "white",
                      textDecoration: "none",
                      width: "100%",
                    }}
                  >
                    {({ blob, url, loading, error }) => {
                      if (blob) {
                        setKYCBlob(blob);
                      }
                      return (
                        <Button variant="contained" style={{ width: "100%" }}>
                          {error
                            ? "error please try again"
                            : loading
                            ? "Loading..."
                            : "Download document"}
                        </Button>
                      );
                    }}
                  </PDFDownloadLink>
                  <a
                    style={{
                      color: "white",
                      textDecoration: "none",
                      width: "100%",
                    }}
                    target="_blank"
                    href={powerFormUrl}
                    rel="noreferrer"
                  >
                    <Button
                      variant="contained"
                      sx={{ px: 2 }}
                      onClick={() => setDocumentSigned(true)}
                      style={{ width: "100%" }}
                    >
                      {"Sign KYC Document"}
                    </Button>
                  </a>
                </Stack>
                <Box my={6}>
                  <Typography paragraph fontWeight="bold">
                    You are not an authorized signatory? No worries! Please fill
                    in the details below, and we will send an email to the
                    designated director, requesting them to sign (and we will
                    copy you in the email so you can track it):{" "}
                  </Typography>
                  <GetDirectorDetailsKYC
                    powerformUrl={powerFormUrl}
                    currentUser={authctx.userDetails.email}
                    submitFunction={getDirectorsRequest}
                    isLoading={isLoadingDirectorsRequest}
                    company_id={String(ngoDetails.uuid)}
                  />
                  {signatureRequestMessage && (
                    <Alert
                      severity={signatureRequestMessage?.messType || "success"}
                      sx={{ mt: 4 }}
                    >
                      {signatureRequestMessage?.message}
                    </Alert>
                  )}
                </Box>
              </>
            ) : (
              <Box my={6}>
                <Alert severity="success">Signature request sent!</Alert>
              </Box>
            )
          ) : (
            // )
            <Alert severity="error">{errMessage || ""}</Alert>
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
}
