import {
  Box,
  Typography,
  OutlinedInput,
  Stack,
  Autocomplete,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { COUNTRIES, minmumBirthDate } from "../constants";
import { SignatoriesType } from "../interface/NGO";
import { regexNameNumbers } from "../constants";
import { DatePicker } from "@mui/x-date-pickers";
import FileUploadComponent from "./FileUpload";

type Props = {
  id: number;
  addSignatory: (e: any) => any;
};

export default function NGOSignatories({ id, addSignatory }: Props) {
  const date = new Date();

  date.setDate(date.getDate() - 1);

  const [signcon, setSignCon] = useState<SignatoriesType>({
    name: "",
    designation: "",
    nationality: "119",
    d_o_b: minmumBirthDate(),
    signatory_id_number: "",
    signatory_id_file: null,
    htmlid: id,
  });

  const setData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name.includes(`name_${id}`)) {
      setSignCon({ ...signcon, name: value });
    }

    if (name.includes(`designation_${id}`)) {
      setSignCon({ ...signcon, designation: value });
    }

    if (name.includes(`dob_${id}`)) {
      setSignCon({ ...signcon, d_o_b: value });
    }

    if (name.includes(`id_${id}`)) {
      setSignCon({ ...signcon, signatory_id_number: value });
    }
  };

  const dirIdAdd = (e: any) => {
    setSignCon({
      ...signcon,
      signatory_id_file: e[`signatory_id_copy_${id}`],
    });
  };

  useEffect(() => {
    addSignatory(signcon);
  }, [signcon]);

  return (
    <Stack direction="column" spacing={1} my={2}>
      <Typography mt={1} fontSize="16px" fontWeight="bold">
        Signator {id}
      </Typography>

      <Stack direction="row" spacing={1}>
        <Box flex={1}>
          <Typography component="label" fontSize="16px" lineHeight="2">
            Name of Authorized Signatory{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id={`name_${id}`}
            type="text"
            size="small"
            value={signcon.name}
            onChange={setData}
            placeholder={"John Doe / Parent Company Name"}
            fullWidth
            error={
              signcon.name.length === 0
                ? false
                : !regexNameNumbers.test(signcon.name)
            }
            name={`name_${id}`}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>
        <Box flex={1}>
          <Typography component="label" fontSize="16px" lineHeight="2">
            Designation/Title{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id={`designation_${id}`}
            type="text"
            size="small"
            value={signcon.designation}
            onChange={setData}
            placeholder={"Manager / Director"}
            fullWidth
            error={
              signcon.designation.length === 0
                ? false
                : !regexNameNumbers.test(signcon.designation)
            }
            name={`designation_${id}`}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Box flex={1}>
          <Typography component="label" fontSize="16px" lineHeight="2">
            Date of Birth{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>

          <DatePicker
            label=""
            value={dayjs(signcon.d_o_b)}
            onChange={(newDate) => {
              const organizedDate = `${newDate?.year()}-${
                typeof newDate?.month() === "number" ? newDate?.month() + 1 : 1
              }-${newDate?.date()}`;

              setSignCon((prev: SignatoriesType) => ({
                ...prev,
                d_o_b: organizedDate,
              }));
            }}
            format="DD/MM/YYYY"
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
            slotProps={{
              textField: {
                size: "small",
                helperText: "DD/MM/YYYY",
                sx: {
                  borderRadius: "13px",
                  backgroundColor: "transparent",
                  width: "100%",
                },
              },
            }}
            maxDate={dayjs(minmumBirthDate())}
          />
        </Box>

        <Box flex={1}>
          <Typography component="label" fontSize="16px" lineHeight="2">
            Nationality{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>

          <Autocomplete
            id={`nationality_${id}`}
            options={COUNTRIES}
            size="small"
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
            value={COUNTRIES[Number(signcon.nationality)]}
            getOptionLabel={(option) => option.name}
            autoHighlight
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                setSignCon({
                  ...signcon,
                  nationality: String(
                    COUNTRIES.findIndex((x) => x.name === newValue.name)
                  ),
                });
              }
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.flag?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.flag?.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Box flex={1} display={"flex"} flexDirection={"column"}>
          <Typography component="label" fontSize="14px" lineHeight="2">
            ID/Passport Number{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id={`id_${id}`}
            type="text"
            size="small"
            value={signcon.signatory_id_number}
            onChange={setData}
            placeholder={"12142132"}
            name={`id_${id}`}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>

        <Box flex={1} display={"flex"} flexDirection={"column"}>
          <Typography component="label" fontSize="14px" lineHeight="2">
            Upload ID/Passport{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <FileUploadComponent
            text="Signatory ID Upload"
            nameValue={`signatory_id_copy_${id}`}
            bring={dirIdAdd}
            required={true}
            dis={false}
            hideDelete={true}
          />
        </Box>
      </Stack>
    </Stack>
  );
}
