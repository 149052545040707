import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Avatar, Backdrop, Button, Chip, Grid } from "@mui/material";
import AuthContext, { UserDetails } from "../../../context/AuthContext";
import NavbarProcess from "../../../components/navs/NavbarProcess";
import PageLayout from "../../Templates/PageLayout";
import Cookies from "js-cookie";
import { useFetchQuery, usePostQuery } from "../../../Hooks/reactQuery";
import { toast } from "react-toastify";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";
import MailLockIcon from "@mui/icons-material/MailLock";
import { appMfaEnabled, emailMfaEnabled } from "../../../utils/userFunctions";

export const styles = {
  paperContainer: {
    backgroundImage: `linear-gradient(120deg, #E6EAF5 0%, #F0E8EE 33%, #ECE9F4 61%, #E9ECF3 100%)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    minHeight: "100vh",
  },
  selectedCard: {
    boxShadow: "0px 0px 10px 0px #425FB4",
    borderRadius: "10px",
  },
};

export default function TwoFactorAuth() {
  const navigate = useNavigate();
  const ctxAuth = useContext(AuthContext);

  const { data, refetch } = useFetchQuery(
    "token/validate-access-token/",
    "token-validation",
    false,
    false,
    Cookies.get("tokenAccess")
  );

  const { data: dataVerify, mutate: mutateOtpVerify } = usePostQuery(
    "auth/otp/update-primary-method",
    ctxAuth.companyDetails.tokenAccess
  );

  //Move to email page
  const {
    data: dataEmailSent,
    mutate: mutateGenerate,
    isLoading: isLoadingGenerate,
  } = usePostQuery(
    "auth/otp/generate-email",
    ctxAuth.companyDetails.tokenAccess
  );

  const { data: dataOtpGenerate, mutate: mutateOtpGenerate } = usePostQuery(
    "auth/otp/generate",
    ctxAuth.companyDetails.tokenAccess
  );

  useEffect(() => {
    const resStatus = dataEmailSent?.data.status;

    if (resStatus === "success") {
      navigate("/two-factor-email-setup");
    }

    if (resStatus === "error") {
      toast.error(
        "An error occured couldn't send verification token to your email. Try again or contact support for help."
      );
    }
  }, [dataEmailSent]);

  useEffect(() => {
    refetch();
  }, [dataVerify]);

  // useEffect(() => {
  //   const resStatus = data?.status;

  //   if (resStatus === "success") {
  //     const resData = data?.data;
      
  //     const userDetails: UserDetails = {
  //       email: resData.email as string,
  //       name: resData.name as string,
  //       kyc_information_added: resData.kyc_information_added as boolean,
  //       isLoggedIn: true,
  //       hasCompany: resData.has_company as boolean,
  //       isMainUser: resData.is_main_user as boolean,
  //       otp_enabled: resData.otp_enabled as boolean,
  //       otp_verified: resData.otp_verified as boolean,
  //       otp_validated: true,
  //       otp_email_enabled: resData.otp_email_enabled as boolean,
  //       otp_email_verified: resData.otp_email_verified as boolean,
  //       primary_2fa_method: resData.primary_2fa_method as string,
  //       roles: resData?.roles ? (resData.roles as string[]) : [],
  //     };
  //     console.log("ctx", ctxAuth.userDetails);
  //     console.log("res", userDetails);
  //     //ctxAuth.setUser(userDetails);
  //   }
  // }, [data]);

  const updatePrimary2fa = (method: string) => {
    const formData = new FormData();
    formData.append("primary_2fa_method", method);
    mutateOtpVerify(formData);
  };

  const emailSelected = useMemo(() => {
    const resStatus = data?.status;

    if (resStatus === "success") {
      const resData = data?.data;
      return emailMfaEnabled(resData) && resData.primary_2fa_method === "email";
    }
  }, [data]);

  const appSelected = useMemo(() => {
    const resStatus = data?.status;

    if (resStatus === "success") {
      const resData = data?.data;
      return appMfaEnabled(resData) && resData.primary_2fa_method === "app";
    }
  }, [data]);

  const handleSetupApp2FA = () => {
    const formData = new FormData();
    mutateOtpGenerate(formData);
  };

  useEffect(() => {
    const resStatus = dataOtpGenerate?.data.status;

    if (resStatus === "success") {
      navigate("/two-factor-app-setup", {
        state: { data: dataOtpGenerate?.data.data },
      });
    }

    if (resStatus === "error") {
      toast.error("Could not generate token");
    }
  }, [dataOtpGenerate]);

  const appbtn = useMemo(() => {
    const resStatus = data?.status;
    const resData = data?.data;

    if (resStatus === "success" && !appMfaEnabled(resData)) {
      return (
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => handleSetupApp2FA()}
          data-cy="setup-app-2fa-btn"
        >
          Setup
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => updatePrimary2fa("app")}
        >
          Make app preferred 2FA method
        </Button>
      );
    }
  }, [data]);

  const emailbtn = useMemo(() => {
    const resStatus = data?.status;
    const resData = data?.data;

    if (resStatus === "success" && !emailMfaEnabled(resData)) {
      return (
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => mutateGenerate(new FormData())}
          data-cy="setup-email-2fa-btn"
        >
          Generate Token
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => updatePrimary2fa("email")}
        >
          Make email preferred 2FA method
        </Button>
      );
    }
  }, [data]);

  return (
    <PageLayout
      contentStyling={{ margin: "2rem" }}
      showBackgroundImage={false}
      CustomNavbar={<NavbarProcess />}
    >
      <>
        <Backdrop
          sx={{
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoadingGenerate}
        >
          <Typography
            variant="inherit"
            textAlign={"center"}
            color={"white"}
            my={2}
          >
            Generating token and sending token...
          </Typography>
        </Backdrop>
        <Box sx={{ p: 4 }}>
          <Typography
            variant="inherit"
            fontSize="30px"
            textAlign={"center"}
            fontWeight="bold"
          >
            Two Factor Authentication (2FA) Methods
          </Typography>
          <Typography
            variant="inherit"
            textAlign={"center"}
            my={2}
            fontSize="18px"
          >
            Add an extra layer of security to your account by enabling
            two-factor authentication (2FA). We recommend using more than one
            method for 2FA to ensure you can always access your account. Select
            a method below to get started.
          </Typography>

          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ gap: 2 }}
          >
            <Grid item p={2} xs={4} sx={appSelected ? styles.selectedCard : {}}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  width={"100%"}
                >
                  {appSelected ? (
                    <Chip
                      label={"Selected"}
                      variant="filled"
                      sx={{
                        backgroundColor: "#425FB4",
                        color: "white",
                      }}
                    />
                  ) : null}
                </Box>

                <Avatar
                  sx={{ width: "200px", height: "200px", bgcolor: "#425FB4" }}
                >
                  <PhonelinkLockIcon sx={{ fontSize: "4rem" }} />
                </Avatar>
                <Typography
                  variant="h5"
                  my={2}
                  textAlign={"center"}
                  fontWeight="bold"
                >
                  Authenticator App
                </Typography>
              </Box>
              <Typography variant="inherit" textAlign={"center"} my={2}>
                Use an Authenticator app or browser window to get two-factor
                authentication codes when prompted.
              </Typography>
              {!appSelected ? appbtn : null}
            </Grid>

            <Grid
              item
              xs={4}
              p={2}
              sx={emailSelected ? styles.selectedCard : {}}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  width={"100%"}
                >
                  {emailSelected ? (
                    <Chip
                      label={"Selected"}
                      variant="filled"
                      sx={{
                        backgroundColor: "#425FB4",
                        color: "white",
                      }}
                    />
                  ) : null}
                </Box>
                <Avatar
                  sx={{ width: "200px", height: "200px", bgcolor: "#425FB4" }}
                >
                  <MailLockIcon sx={{ fontSize: "4rem" }} />
                </Avatar>
                <Typography
                  variant="h5"
                  my={2}
                  textAlign={"center"}
                  fontWeight="bold"
                >
                  Email
                </Typography>
              </Box>
              <Typography variant="inherit" textAlign={"center"} my={2}>
                Get one time codes sent to your email to complete authentication
                requests. Click the button below to setup email 2FA.
              </Typography>

              {!emailSelected ? emailbtn : null}
            </Grid>
          </Grid>
        </Box>
      </>
    </PageLayout>
  );
}
