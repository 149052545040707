import { useContext } from "react";
import { useForm, Controller } from "react-hook-form";

// MATERIAL UI
import {
  OutlinedInput,
  Box,
  FormControl,
  Typography,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";

// UTILs
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

// get director form fields
export type TGetDirectorsDetailsKYCFields = {
  name: string;
  email: string;
  company_id: string;
  current_user: string;
  docusign_url: string;
};

interface IGetDirectorsFormKYCProps {
  powerformUrl: string;
  currentUser: string;
  submitFunction: (data: TGetDirectorsDetailsKYCFields) => void;
  isLoading: boolean;
  company_id: string;
}

export default function GetDirectorDetailsKYC(
  props: IGetDirectorsFormKYCProps
) {
  const ctxAuth = useContext(AuthContext);
  const navigate = useNavigate();

  const { powerformUrl, currentUser, submitFunction, isLoading, company_id } =
    props;

  // register form fields
  const { register, control, handleSubmit, formState } =
    useForm<TGetDirectorsDetailsKYCFields>({
      reValidateMode: "onChange",
      mode: "onSubmit",
      defaultValues: {
        name: "",
        email: "",
        company_id: company_id,
        current_user: currentUser,
        docusign_url: powerformUrl,
      },
    });
  const { isValid, errors } = formState;

  return (
    <Box>
      <form onSubmit={handleSubmit(submitFunction)}>
        <FormControl variant="outlined" fullWidth>
          <Typography component="label" fontSize="16px" lineHeight="2">
            Name:
          </Typography>
          <Controller
            control={control}
            name="name"
            rules={{ required: "Director name required" }}
            render={({ field: { onChange, value } }) => (
              <OutlinedInput
                id="outlined-name-required"
                name="director_sig_name"
                type="text"
                placeholder="Jane Doe"
                value={value}
                onChange={onChange}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                }}
              />
            )}
          />
          <Typography
            fontSize="14px"
            fontWeight="bold"
            color="error.main"
            minHeight="10px"
            sx={{ marginBottom: "10px", marginLeft: 1 }}
          >
            <> {errors.name && errors.name.message}</>
          </Typography>
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <Typography component="label" fontSize="16px" lineHeight="2">
            Email:
          </Typography>
          <Controller
            control={control}
            name="email"
            rules={{ required: "Director email required" }}
            render={({ field: { onChange, value } }) => (
              <OutlinedInput
                id="outlined-email-required"
                type="email"
                name="director_sig_email"
                placeholder="abc@gmail.com"
                value={value}
                onChange={onChange}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                }}
              />
            )}
          />
          <Typography
            fontSize="14px"
            fontWeight="bold"
            color="error.main"
            minHeight="10px"
            sx={{ marginBottom: "20px", marginLeft: 1 }}
          >
            <> {errors.email && errors.email.message}</>
          </Typography>
        </FormControl>
        <Button
          // disabled={!isValid}
          type="submit"
          variant="contained"
          sx={{ m: "auto", display: "block", textTransform: "uppercase" }}
        >
          {isLoading ? (
            <Stack sx={{ color: "primary" }}>
              <CircularProgress size={20} color="inherit" />
            </Stack>
          ) : (
            "Send Signature Request"
          )}
        </Button>
      </form>
    </Box>
  );
}
