import { Cookies } from "react-cookie-consent";
import secureLocalStorage from "react-secure-storage";
import { companyDetails, userDetails } from "../context/AuthContext";
import { Auth } from "../context/AuthContext";

export const ClearUserDetails = (ctxAuth: Auth) => {
  ctxAuth.setCompany(companyDetails);

  ctxAuth.setUser(userDetails);

  secureLocalStorage.clear();
  Cookies.remove("tokenRefresh");
  Cookies.remove("tokenAccess");
  Cookies.remove("otpValidated");
};
