import { useMutation } from "react-query";
import { generateOTPFn, loginFn } from "../../utils/queris";
import { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import AuthContext, {
  CompanyDetails,
  UserDetails,
} from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { mfaEnabled } from "../../utils/userFunctions";

export const useLogin = () => {
  const navigate = useNavigate();
  const ctxAuth = useContext(AuthContext);
  const [errorTxt, setErrorTxt] = useState<string | null>(null);

  const { data, mutate, isSuccess, isError, error, isLoading } = useMutation(
    loginFn,
    {
      onError: (err: any) => {
        return err;
      },
    }
  );

  const { mutate: mutateOtpGenerate, isSuccess: isSuccessGenerate } =
    useMutation(generateOTPFn, {
      onError: (err: any) => {
        return err;
      },
    });

  //Set error message
  useEffect(() => {
    if (isError) {
      if (error?.code === "ERR_NETWORK") {
        setErrorTxt("Network Error");
      } else {
        setErrorTxt(error?.message);
      }
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess && !data?.data) {
      setErrorTxt("Email or password is incorect try again.");
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      // get status of the request
      const statusRes = data?.status;
      if (statusRes === "success") {
        // check if the user has otp enabled and verified
        const res = data.data;
        if (
          (res.otp_email_enabled &&
            res.otp_email_verified &&
            res.primary_2fa_method === "email") ||
          (res.otp_email_enabled &&
            !res.otp_enabled &&
            res.primary_2fa_method !== "email")
        ) {
          // set Token
          const res_child = res.tokens;
          Cookies.set("tokenAccess", res_child.access, {
            path: "/",
            expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
          });

          Cookies.set("tokenRefresh", res_child.refresh, {
            path: "/",
            expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
          });
          login(res, false);
        } else {
          login(res);
        }
      } else {
        setErrorTxt(data.message || "Email or password is incorect try again.");
      }
    }
  }, [isSuccess, data]);

  // if token is set then generate otp
  useEffect(() => {
    if (
      Cookies.get("tokenAccess") !== undefined &&
      (data?.data?.primary_2fa_method === "email" ||
        (data?.data?.primary_2fa_method !== "email" &&
          data?.data?.otp_email_enabled &&
          !data?.data?.otp_enabled))
    ) {
      mutateOtpGenerate(data?.data.tokens.access);
    }
  }, [data]);

  // if otp is generated then login
  useEffect(() => {
    if (isSuccessGenerate && data.token.access?.length > 0 && data) {
      login(data, false);
    }
  }, [isSuccessGenerate, data?.data]);

  const login = (data: any, otp_validated?: boolean) => {
    if ("roles" in data) {
      if (data?.roles?.includes("client_company")) {
        if (data.has_company) {
          // check if company is banned
          if (data.company_banned) {
            return navigate("/company-access-verification");
          }

          // check if documents are verified
          if (data.documents_verified) {
            // check if main user
            if (mfaEnabled(data)) {
              centralDataHandler(
                data,
                otp_validated ? "/dashboard" : "/validate-otp",
                otp_validated ? otp_validated : false,
                otp_validated
              );
            } else if (data.is_main_user) {
              centralDataHandler(data, "/dashboard");
            } else {
              navigate("/main-user-verification");
            }
            //Check if company has been created but not signed
          } else if (!data.company_kyc_form_signed) {
            centralDataHandler(data, "/kyc-documents");
          } else {
            navigate("/verification");
          }
          // }
        } else {
          //check if main user
          if (data.is_main_user) {
            centralDataHandler(data, "/company-setup");
          } else {
            navigate("/main-user-verification");
          }
        }
      } else if (data?.roles?.includes("client_individual")) {
        if (data.kyc_information_added) {
          if (!data.documents_verified) {
            navigate("/verification");
          } else {
            if (mfaEnabled(data)) {
              centralDataHandler(
                data,
                otp_validated ? "/dashboard" : "/validate-otp",
                otp_validated ? otp_validated : false,
                otp_validated
              );
            } else if (data.is_main_user) {
              centralDataHandler(data, "/dashboard");
            } else {
              navigate("/main-user-verification");
            }
          }
        } else {
          centralDataHandler(data, "/user-setup");
        }
      } else {
        setErrorTxt(
          "We have recently upgraded our system. If you are having trouble logging in, please contact our support team."
        );
      }
    }
  };

  const centralDataHandler = (
    data: any,
    nav: string,
    loginUser = true,
    otp_validated = false
  ) => {
    Cookies.set("tokenAccess", data.tokens?.access as string, {
      path: "/",
      expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    });

    Cookies.set("tokenRefresh", data.tokens?.refresh as string, {
      path: "/",
      expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    });

    Cookies.set("otpValidated", otp_validated.toString(), {
      path: "/",
      expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    });

    const backDetails: CompanyDetails = {
      companyName: data.company_name as string,
      tokenAccess: data.tokens.access,
      tokenRefresh: data.tokens.refresh,
      referenceNumber: data.reference_number as string,
      company_kyc_form_signed: data.company_kyc_form_signed as boolean,
      isBannedCompany: data.company_banned as boolean,
      documentsVerified: data.documents_verified as boolean,
    };

    const userDetails: UserDetails = {
      email: data.email as string,
      name: data.name as string,
      kyc_information_added: data.kyc_information_added as boolean,
      isLoggedIn: loginUser,
      hasCompany: data.has_company as boolean,
      isMainUser: data.is_main_user as boolean,
      otp_enabled: data.otp_enabled as boolean,
      otp_verified: data.otp_verified as boolean,
      otp_validated: otp_validated as boolean,
      otp_email_enabled: data.otp_email_enabled as boolean,
      otp_email_verified: data.otp_email_verified as boolean,
      primary_2fa_method: data.primary_2fa_method as string,
      roles: data?.roles ? (data.roles as string[]) : [],
    };

    ctxAuth.setCompany(backDetails);
    ctxAuth.setUser(userDetails);
    navigate(nav);
    //ctxAuth.setNavLink(nav);
  };

  return {
    data,
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    login,
    errorTxt,
  } as const;
};
