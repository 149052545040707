import React, { useState, useMemo, useContext, useEffect } from "react";
import NavbarProcess from "../../components/navs/NavbarProcess";

import {
  Alert,
  Box,
  Button,
  FormGroup,
  Typography,
  Grid,
  Stack,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Companystyles } from "../../theme/Style";
import { UserDetailsForm } from "../../interface/Common";
import PageLayout from "../Templates/PageLayout";

// RESOUCES
import CompanyDetailsStepper from "../../resources/auth/companyDetailsStepper.svg";
import CompanyDetailsStepperB2C from "../../resources/auth/companyDetailsStepperB2C.svg";
import UserDetails from "../../components/kyc/user/userDetails";
import AuthContext from "../../context/AuthContext";
import { useCreateUserKYC } from "../../Hooks/createUserKYC";

export default function UserSetUp() {
  const ctxAuth = useContext(AuthContext);
  const [response, newUser, isLoading] = useCreateUserKYC();
  const [userDetailsIcon, setUserDetailsIconVar] = useState(false);
  const [userConsent, setUserConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [formValues, setFormValues] = useState<UserDetailsForm | any>();

  const [mess, setMess] = useState("");
  const [btnMess, setBtnMess] = useState("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formValuesConsent = {
      ...formValues,
      user_consent: userConsent,
      marketing_consent: marketingConsent,
    };
    newUser(formValuesConsent);
  };

  const isValid = useMemo(() => {
    if (!userDetailsIcon) {
      setBtnMess(
        "Please fill all fields in the company detail section correctly."
      );
      return false;
    }
    return true;
  }, [userDetailsIcon]);

  useEffect(() => {
    if (response === "User KYC completed") {
      // logout user and redirect to verification page

      ctxAuth.logout("/verification", true);
    } else if (response === "error company") {
      setMess("An error occurred. Please try again");
    }
  }, [response]);

  return (
    <PageLayout showBackgroundImage={false} CustomNavbar={<NavbarProcess />}>
      <Grid container py={2}>
        {mess ? (
          <Grid item xs={12}>
            <Alert severity="error">{mess}</Alert>
          </Grid>
        ) : null}

        <Grid item xs={12} mb={2}>
          <Typography
            variant="h4"
            style={{
              textAlign: "left",
              fontWeight: "bold",
              paddingBottom: "8px",
              fontSize: "30px",
            }}
          >
            Create your Bonto Account
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 900 }}>
            Step 3 - User Account Setup
          </Typography>
        </Grid>
        <Grid item xs={12} lg={7}>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <Accordion
              defaultExpanded={true}
              style={{ backgroundColor: "transparent" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: `#425FB4` }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: `#F5F5F5` }}
              >
                {userDetailsIcon ? (
                  <CheckCircleIcon
                    sx={{ color: `#425FB4`, marginRight: 2 }}
                    fontSize="small"
                    data-cy="userCircle"
                  />
                ) : null}
                <Typography variant="body2" sx={{ color: `#425FB4` }}>
                  User details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <UserDetails
                  getUserDetailsIcon={setUserDetailsIconVar}
                  getFormValues={setFormValues}
                />
              </AccordionDetails>
            </Accordion>

            <Typography mt={2} mb={1} fontSize="14px">
              These documents are required for KYC by Bonto Kenya Money Transfer
              Limited, to fulfil their obligations towards the Central Bank of
              Kenya. By clicking “Submit”, you agree for Bonto Limited to share
              this data with Bonto Kenya Money Transfer Limited.
            </Typography>

            <Box
              sx={{
                paddingY: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="consent"
                    onChange={(e) => setUserConsent(e.target.checked)}
                  />
                }
                label={
                  <Typography fontSize="14px">
                    I confirm that the information provided is accurate and
                    true. By checking this box, I consent to the use of my data
                    by Bonto Kenya Money Transfer Limited for transaction
                    purposes.
                  </Typography>
                }
              />
            </Box>

            <Box
              sx={{
                paddingY: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="marketing_consent"
                    onChange={(e) => setMarketingConsent(e.target.checked)}
                  />
                }
                label={
                  <Typography fontSize="14px">
                    I agree to receive occasional marketing messages containing
                    information about the Company's products and services. I
                    understand that I can opt out of these communications at any
                    time by contacting Bonto Kenya Money Transfer Limited
                    directly.
                  </Typography>
                }
              />
            </Box>

            <FormGroup row style={Companystyles.groupsB}>
              <Button
                variant="contained"
                type="submit"
                sx={{ width: "100%", mt: 2 }}
                disabled={!isValid || isLoading || !userConsent}
              >
                {isLoading ? (
                  <Stack sx={{ color: "primary" }}>
                    <CircularProgress size={20} color="inherit" />
                  </Stack>
                ) : (
                  "Submit"
                )}
              </Button>

              {!isValid && (
                <Alert sx={{ width: "100%", mt: 2 }} severity="error">
                  Why is the button disabled? {btnMess}
                </Alert>
              )}
            </FormGroup>
          </form>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          display={{ md: "flex", sm: "none" }}
          alignItems="flex-start"
          justifyContent="flex-end"
        >
          <Box
            sx={{ backgroundColor: "rgba(112, 112, 112, 0.03)" }}
            p={4}
            width="85%"
            ml="auto"
          >
            <img
              src={
                ctxAuth.userDetails.roles[0] === "client_company"
                  ? CompanyDetailsStepper
                  : CompanyDetailsStepperB2C
              }
              width="100%"
              height="100%"
            />
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
}
