import { Button, Typography, Grid, CircularProgress, Box } from "@mui/material";
import PageLayout from "./Templates/PageLayout";
import NavbarProcess from "../components/navs/NavbarProcess";

// ICON
import { useParams, useNavigate } from "react-router-dom";
import { useFetchQuery } from "../Hooks/reactQuery/useFetchQuery";
import VerificationIconSvg from "../resources/icons/verification-icon.png";

export default function EmailVerfication() {
  const navigate = useNavigate();
  const params = useParams();
  const { isError, isSuccess, isLoading } = useFetchQuery(
    `auth/verify-email?token=${params.token}`,
    "verify-email",
    true,
    false
  );

  return (
    <PageLayout
      CustomNavbar={<NavbarProcess noIcon />}
      showBackgroundImage={false}
    >
      <Grid container py={4}>
        <Grid
          item
          lg={6}
          xs={12}
          py={{ lg: 4, xs: 2 }}
          px={{ lg: 6, xs: 2 }}
          sx={{
            background: "rgba(66, 95, 180, 0.04)",
            margin: "auto",
          }}
        >
          {isLoading ? (
            <Typography variant="h5" textAlign="center" fontWeight={900} mb={4}>
              Verifing your email
            </Typography>
          ) : null}

          {isSuccess ? (
            <>
              <img
                src={VerificationIconSvg}
                width="90%"
                height="auto"
                style={{ margin: "auto", display: "block" }}
                alt="email-verified-icon"
              />
              <Typography
                variant="h5"
                textAlign="center"
                fontWeight={900}
                my={4}
              >
                Your email has been verified! Press the button below to sign in.
              </Typography>
            </>
          ) : null}

          {isError ? (
            <Typography variant="h5" textAlign="center" fontWeight={900} mb={4}>
              An error has occured. Please try again later. To access your
              account, please login.
            </Typography>
          ) : null}

          {isLoading ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress variant="indeterminate" size={"4rem"} />
            </Box>
          ) : null}

          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/sign-in")}
            sx={{ width: "100%", p: 2, mt: 2 }}
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </PageLayout>
  );
}
