import { IAPIResource } from "./types";


// this are global variables to be used when calling backend endpoints
export const API_RESOURCES: IAPIResource = {
  TRANSACTIONS: "transactions/view/",
  CREATE_TRANSACTION: "transactions/create/",
  USER_PROFILE: "user-profile",
  LARGE_CASH_FORM_UPDATE: "transactions/large-transaction-form-update/",
  KYC_FORM_UPDATE: "auth/company-kyc-upload/",
  KYC_SIGNATURE_REQUEST: "auth/company-kyc-signature-request/",
  LARGE_CASH_FORM_SIGNATURE_REQUEST: "transactions/signature-request/",
  PROOF_OF_PAYMENT_UPDATE: "transactions/proof-of-payment-upload/",
};
