import { useState } from "react";
import { Box, Typography, Grid, Select, MenuItem } from "@mui/material";
import Company from "./Company";
import PageLayout from "../Templates/PageLayout";
import NavbarProcess from "../../components/navs/NavbarProcess";
import CompanyDetailsStepper from "../../resources/auth/companyDetailsStepper.svg";
import NGOs from "./NGO/NGOs";
import { OrganizationType } from "../../constants";
export default function OrgGroup() {
  const [orgType, setOrgType] = useState("Business name/Sole Proprietorship");

  return (
    <PageLayout showBackgroundImage={false} CustomNavbar={<NavbarProcess />}>
      <Grid container py={2}>
        <Grid item xs={12} mb={2}>
          <Typography
            variant="h4"
            style={{
              textAlign: "left",
              fontWeight: "bold",
              paddingBottom: "8px",
              fontSize: "30px",
            }}
          >
            Create your Bonto Account
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 900 }}>
            Step 3 - Upload your Documents
          </Typography>
        </Grid>

        <Grid item xs={12} lg={7}>
          <Grid
            item
            xs={12}
            mb={2}
            gap={2}
            display={"flex"}
            flexDirection={"column"}
          >
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Please select the type of Organization/Company to get the correct
              KYC page{" "}
            </Typography>

            <Select
              id="industry"
              name="industry"
              size="small"
              onChange={(event: any) => {
                setOrgType(event.target.value);
              }}
              value={orgType}
              sx={{
                borderRadius: "4px",
                backgroundColor: "white",
              }}
              MenuProps={{ disableScrollLock: true }}
            >
              {OrganizationType?.map((number: string) => (
                <MenuItem key={number} value={number}>
                  {number}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Box>
            {orgType === "Non-Governmental Organization" ? (
              <NGOs business_type={"NGO"} />
            ) : (
              <Company business_type={orgType} />
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          display={{ md: "flex", sm: "none" }}
          alignItems="flex-start"
          justifyContent="flex-end"
        >
          <Box
            sx={{ backgroundColor: "rgba(112, 112, 112, 0.03)" }}
            p={4}
            width="85%"
            ml="auto"
          >
            <img src={CompanyDetailsStepper} width="100%" height="100%" />
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
}
