import { useMemo } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

//CONTEXT
import { COUNTRIES } from "../constants";
import { CountriesType } from "../interface/Common";

import { SignatoriesType, NGODetailsType } from "../interface/NGO";

type Props = {
  ngoDetails: NGODetailsType;
  signatory: SignatoriesType[];
  authDetails: any;
};

const NGODocument = ({
  ngoDetails,
  signatory,
  authDetails,
}: Props): JSX.Element => {
  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#F0E8EE",
      fontFamily: "Times-Roman",
      fontSize: 12,
      lineHeight: 1.4,
      color: "black",
      width: "100%",
      paddingHorizontal: "2%",
    },
    section: {
      padding: "0px 0px",
    },

    fieldText: {
      height: "100%",
      width: "100%",
      textAlign: "center",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      hyphens: "auto",
    },
    headerSection: {
      //backgroundColor: "rgba(66, 95, 180, 0.14)",
      padding: "2% 2% 2% 2%", //top right bottom left
    },
    title: {
      fontWeight: "bold",
      fontFamily: "Times-Bold",
      textTransform: "uppercase",
      textAlign: "left",
      fontSize: "12px",
      textDecoration: "none",
    },
    note: {
      textAlign: "left",
      fontSize: "12px",
      textDecoration: "none",
    },

    flexDisplay: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      backgroundColor: "red",
    },
    flexDisplayTable: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      borderTop: "1px solid black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    },
    flexDisplayTableShareHolders: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      border: "1px solid black",
      marginTop: "10px",
      marginBottom: "100px",
    },
    flexDisplayRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      maxHeight: "60px",
      borderBottom: "1px solid black",
    },
    flexDisplayRowShort: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      maxHeight: "40px",
      borderBottom: "1px solid black",
    },
    flexDisplayTitleShareHolders: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxHeight: "120px",
      borderBottom: "1px solid black",
      backgroundColor: "#42a5f5",
    },
    input: {
      borderBottom: "1px solid black",
      height: "20px",
    },
  });

  const countries = useMemo(() => {
    const name_list: string[] = [];

    ngoDetails.country_of_ops.split(",").map((e: string) => {
      COUNTRIES.filter((coun: CountriesType) => {
        if (coun.code === e) name_list.push(coun.name);
      });
    });

    return name_list;
  }, [ngoDetails.country_of_ops]);

  const dateChange = useMemo(() => {
    const myDate = new Date(ngoDetails.company_reg_date);

    return `${myDate.getDate() < 10 ? "0" : ""}${myDate.getDate()}-${
      myDate.getMonth() + 1 < 10 ? "0" : ""
    }${myDate.getMonth() + 1}-${myDate.getFullYear()}`;
  }, [ngoDetails.company_reg_date]);

  const signatoryView = useMemo(() => {
    return signatory?.map((e: SignatoriesType, idx: number) => {
      const number = Number(e.nationality);
      return (
        <View style={styles.flexDisplayRow} key={idx}>
          <View style={{ minWidth: "30%", borderRight: "1px solid black" }}>
            <Text style={{ ...styles.fieldText }}>{e.name}</Text>
          </View>

          <View style={{ minWidth: "10%", borderRight: "1px solid black" }}>
            <Text style={{ ...styles.fieldText }}>{e.designation}</Text>
          </View>

          <View style={{ minWidth: "20%", borderRight: "1px solid black" }}>
            <Text style={{ ...styles.fieldText }}>
              {COUNTRIES[number].name}
            </Text>
          </View>

          <View style={{ minWidth: "20%", borderRight: "1px solid black" }}>
            <Text style={{ ...styles.fieldText }}>{e.d_o_b}</Text>
          </View>

          <View style={{ minWidth: "20%" }}>
            <Text style={{ ...styles.fieldText }}>{e.signatory_id_number}</Text>
          </View>
        </View>
      );
    });
  }, [signatory]);

  return (
    <Document title="Forex Partner Due Diligence Form">
      <Page size="A4" style={styles.page}>
        <View style={{ ...styles.headerSection }}>
          <Text style={{ ...styles.note }}>
            Completing this form and providing the information and documents
            requested is required as part of the KYC process of BKMTL. It is
            part of the requirements towards the Central Bank of Kenya.
            Information provided must be accurate and complete.
          </Text>

          <Text style={{ ...styles.note, marginTop: 8 }}>
            If any part of this form is not applicable to your business, please
            indicate by putting N/A.
          </Text>
        </View>

        <View style={{ ...styles.headerSection }}>
          <Text style={{ ...styles.title }}>
            Section 1: General Organization Information
          </Text>
        </View>

        <View style={styles.flexDisplayTable}>
          <View style={styles.flexDisplayRow}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>1.</Text>
            </View>
            <View
              style={{
                minWidth: "40%",
                borderRight: "1px solid black",
                paddingHorizontal: 2,
              }}
            >
              <Text style={{ ...styles.fieldText }}>
                {`Registered name of organization`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {ngoDetails.company_name}
              </Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>2.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`Organization's registration number`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {ngoDetails.company_registration}
              </Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>3.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`Country of Registration`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {COUNTRIES[ngoDetails.country_of_registration].name}
              </Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>4.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`Date of Registration`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>{dateChange}</Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>5.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`Tax Identification Number`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>{ngoDetails.kra_pin}</Text>
            </View>
          </View>

          <View style={styles.flexDisplayRow}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>6.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`Registered Post Office Address and Physical Address`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {`${ngoDetails.P_O_BOX}
                ${ngoDetails.billing_address}
                `}
              </Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>7.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`List of Countries of Operations`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {countries.toString()}
              </Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>8.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`Type of Organization/Company`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>{ngoDetails.industry}</Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>9.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`Description of NGO Activities`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {ngoDetails.nature_of_business}
              </Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>10.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>{`Source of funds`}</Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {ngoDetails.number_of_employees}
              </Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>11.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`Number of Employees`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {ngoDetails.number_of_employees}
              </Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>12.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`Office Phone Number`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {ngoDetails.company_telephone_number}
              </Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>13.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`Principal Contact Person`}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {authDetails.companyDetails.name ||
                  authDetails.userDetails.name}
              </Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>14.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>
                {`Principal Contact Person’s email address `}
              </Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {authDetails.companyDetails.email ||
                  authDetails.userDetails.email}
              </Text>
            </View>
          </View>

          <View style={styles.flexDisplayRowShort}>
            <View style={{ minWidth: "5%", borderRight: "1px solid black" }}>
              <Text style={{ ...styles.fieldText }}>15.</Text>
            </View>
            <View style={{ minWidth: "40%", borderRight: "1px solid black" }}>
              <Text
                style={{ ...styles.fieldText }}
              >{`Organization Website`}</Text>
            </View>
            <View style={{ minWidth: "55%" }}>
              <Text style={{ ...styles.fieldText }}>
                {ngoDetails.company_website}
              </Text>
            </View>
          </View>
        </View>
      </Page>

      <Page size={"A4"} style={styles.page}>
        <View style={{ ...styles.headerSection }}>
          <Text style={{ ...styles.title }}>
            Section 2: Organization Structure
          </Text>
        </View>

        <View style={styles.flexDisplayTableShareHolders}>
          <View style={styles.flexDisplayTitleShareHolders}>
            <Text style={{ ...styles.title }}>
              DIRECTRORS/AUTHORIZED SIGNATORIES
            </Text>
            <Text
              style={{
                ...styles.title,
              }}
            >
              An authorized signatory is an individual who has been formally
              designated by the Board of Directors or governing body to sign
              legal documents, contracts, and financial transactions on behalf
              of the organization.
            </Text>
          </View>

          <View style={styles.flexDisplayRow}>
            <View
              style={{
                minWidth: "30%",
                borderRight: "1px solid black",
                paddingHorizontal: "10px",
              }}
            >
              <Text style={{ ...styles.fieldText }}>
                Name of Authorized Signatory{" "}
              </Text>
            </View>

            <View
              style={{
                minWidth: "10%",
                borderRight: "1px solid black",
                paddingHorizontal: "10px",
              }}
            >
              <Text style={{ ...styles.fieldText }}>Designation/Title</Text>
            </View>

            <View
              style={{
                minWidth: "20%",
                borderRight: "1px solid black",
                paddingHorizontal: "10px",
              }}
            >
              <Text style={{ ...styles.fieldText }}>Nationality</Text>
            </View>

            <View
              style={{
                minWidth: "20%",
                borderRight: "1px solid black",
                paddingHorizontal: "10px",
              }}
            >
              <Text style={{ ...styles.fieldText }}>Date of Birth</Text>
            </View>

            <View
              style={{
                minWidth: "20%",
                paddingHorizontal: "10px",
              }}
            >
              <Text style={{ ...styles.fieldText }}>ID/Passport Number</Text>
            </View>
          </View>

          {signatoryView}
        </View>

        <View
          style={{ display: "flex", flexDirection: "row", marginTop: "16px" }}
        >
          <Text style={{ width: "40%" }}>
            Name of the Authorized Signatory:
          </Text>
          <Text style={{ ...styles.input, width: "60%" }}></Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginVertical: "2%",
          }}
        >
          <Text style={{ width: "15%" }}>Signature:</Text>
          <Text style={{ ...styles.input, width: "85%" }}></Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginVertical: "2%",
          }}
        >
          <Text style={{ width: "25%" }}>Designation/Title:</Text>
          <Text style={{ ...styles.input, width: "75%" }}></Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginVertical: "2%",
          }}
        >
          <Text style={{ width: "10%" }}>Date:</Text>
          <Text style={{ ...styles.input, width: "90%" }}></Text>
        </View>
      </Page>
    </Document>
  );
};
export default NGODocument;
