import axios from "axios";
import { main_url } from "../constants";
import { SignInValues } from "../interface";

export async function loginFn(formValues: SignInValues) {
  return axios
    .post(
      `${main_url}auth/login/`,
      {
        email: formValues.email,
        password: formValues.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        return err.message;
      }
      return err.response.data;
    });
}

export async function generateOTPFn(token: string) {
  return axios
    .post(
      `${main_url}auth/otp/generate-email`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        return err.message;
      }
      return err.response.data;
    });
}

export async function validateAccessTokenFn(token: string | undefined) {
  return axios
    .get(`${main_url}token/validate-access-token/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      timeout: 30000,
    })
    .then((res) => res.data);
}
