import { useState, useEffect, useCallback, useContext } from "react";
import { usePost } from ".";
import AuthContext from "../context/AuthContext";
import { NGOValues, SignatoriesType } from "../interface/NGO";

export const useCreateSignatories = () => {
  const authctx = useContext(AuthContext);

  const [data, callApi, isLoading] = usePost(
    authctx.companyDetails.tokenAccess,
    true
  );
  const [responseSignatory, setResponse] = useState("");

  const newSignatory = useCallback((formValues: NGOValues, id: string) => {
    const formData = new FormData();

    const keyValues = Object.keys(formValues.signatoryObj);

    for (const key of keyValues) {
      const signatoryData: any = formValues.signatoryObj[key];

      for (const signKey of Object.keys(signatoryData)) {
        formData.append(`${signKey}_${key}`, signatoryData[signKey]);
      }
    }

    formData.append("company", id);

    callApi(formData, `auth/signatories/`);
  }, []);

  useEffect(() => {
    const resStatus = data?.status;

    if ("error" in data) {
      setResponse("error signatories");
    }

    if (resStatus === "success") {
      setResponse("signatory added");
    } else if (resStatus === "error") {
      setResponse("error signatories");
    }
  }, [data]);

  return [responseSignatory, newSignatory, isLoading] as const;
};
