import {useEffect, useMemo, useState} from "react";

import {Box, Button, FormGroup, Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {DirectorInterface} from "../../interface/Common";
import Directors from "../Directors";

//props interface
type Props = {
  getDirectors: (e: DirectorInterface) => void;
  getDirectorsIconStatus: (e: boolean) => void;
};

export default function CompanyDirectors({
                                           getDirectors,
                                           getDirectorsIconStatus,
                                         }: Props) {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const sizeMatch = matches ? true : false;

  const [formValues, setFormValues] = useState<DirectorInterface>({
    directors_values: {},
  });

  const directors = (e: any) => {
    if (e.id !== undefined) {
      let firstName = "";
      let middleName = "";
      let lastName = "";
      let directorFile: File | null = null;

      if (e.fName) {
        firstName = e.fName["first_name_" + e.id];
      }

      if (e.mName) {
        middleName = e.mName["middle_name_" + e.id];
      }

      if (e.lName) {
        lastName = e.lName["last_name_" + e.id];
      }

      if (e.dir && !("fileRes" in e.dir)) {
        directorFile = e.dir["director_id_copy_" + e.id];
      }

      const boltsbind = {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        director_id_copy: directorFile,
      };

      setFormValues((prev: DirectorInterface) => {
        return {
          directors_values: { ...prev.directors_values, [e.id]: boltsbind },
        };
      });
    }
  };

  const allowNext = useMemo(() => {
    let isFormatGood = true;
    Object.keys(formValues.directors_values).forEach((e: any) => {
      if (
          formValues.directors_values[e].first_name === "" ||
          formValues.directors_values[e].last_name === "" ||
          formValues.directors_values[e].director_id_copy === null
      ) {
        isFormatGood = false;
      }
    });

    return Object.keys(formValues.directors_values).length >= 10
        ? false
        : isFormatGood;
  }, [formValues]);

  const [counter, changeCounter] = useState(1);

  const [dirnumber, setdirnumber] = useState([
    <Directors key={1} id={1} addDirector={directors} />,
  ]);

  const unifiedCounter = (
      type: string,
      change: any,
      minItems: number,
      maxItems: number,
  ) => {
    if (type === "sub") {
      change((prev: any) => {
        if (prev !== minItems) return --prev;
        return prev;
      });

      const lastItemDirectors = Object.keys(formValues.directors_values)[
      Object.keys(formValues.directors_values).length - 1
          ];

      setFormValues((prev: DirectorInterface) => {
        delete prev.directors_values[lastItemDirectors];
        return {
          directors_values: { ...prev.directors_values },
        };
      });
    } else {
      change((prev: any) => {
        if (prev < maxItems) return ++prev;
        return prev;
      });
    }
  };

  useEffect(() => {
    if (dirnumber.length < counter) {
      setdirnumber((prev) => [
        ...prev,
        <Directors
            key={dirnumber.length + 1}
            id={dirnumber.length + 1}
            addDirector={directors}
        />,
      ]);
    } else {
      if (dirnumber.length > 1) {
        //Remove item from elements
        if (counter < dirnumber.length) {
          dirnumber.pop();
          setdirnumber([...dirnumber]);
        }
      }
    }
  }, [counter]);

  const levels = useMemo(() => dirnumber.map((e) => e), [dirnumber]);

  useEffect(() => {
    let isFormatGood = true;
    Object.keys(formValues.directors_values).forEach((e: any) => {
      if (
          formValues.directors_values[e].first_name === "" ||
          formValues.directors_values[e].last_name === "" ||
          formValues.directors_values[e].director_id_copy === null
      ) {
        isFormatGood = false;
      }
    });

    getDirectors(formValues);
    getDirectorsIconStatus(isFormatGood);
  }, [formValues, getDirectors, getDirectorsIconStatus]);

  return (
      <FormGroup row={sizeMatch}>
        <Typography mt={4} fontSize="14px" fontWeight="bold">
          Please provide the following information for ALL directors of the
          company
        </Typography>

        {levels}

        {dirnumber.length === 10 ? (
            <Typography mt={2} mb={1} fontSize="14px" color={"InfoText"}>
              A maximum of 10 directors can be added. Contact us if you have more
              directors.{" "}
            </Typography>
        ) : null}

        <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
        >
          <Stack
              spacing={{ xs: 1, sm: 2 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              direction={{ xs: "column", sm: "column", md: "row" }}
          >
            <Button
                fullWidth
                variant="contained"
                sx={{ marginRight: "4px" }}
                disabled={!allowNext}
                onClick={() => unifiedCounter("add", changeCounter, 1, 10)}
            >
              Add Director
            </Button>
            <Button
                fullWidth
                variant="contained"
                onClick={() => unifiedCounter("sub", changeCounter, 1, 10)}
                disabled={dirnumber.length === 1}
            >
              Remove Director
            </Button>
          </Stack>
        </Box>
      </FormGroup>
  );
}
