import { useState, useEffect, useCallback, useContext } from "react";
import { usePost } from ".";
import AuthContext from "../context/AuthContext";
import { CompanyValues } from "../interface/Common";

export const useCreateDirectors = () => {
  const authctx = useContext(AuthContext);

  const [data, callApi, isLoading] = usePost(
    authctx.companyDetails.tokenAccess,
    true
  );
  const [responseDirectors, setResponse] = useState("");

  const newDirerctor = useCallback(
    (formValues: CompanyValues | any, id: string) => {
      const formData = new FormData();

      for (const director of Object.keys(formValues.directors_values)) {
        const directorData = formValues.directors_values[director];

        for (const key of Object.keys(directorData)) {
          formData.append(`${key}_${director}`, directorData[key]);
        }
      }

      formData.append("dir_company_id", id);
      callApi(formData, `auth/director/`);
    },
    []
  );

  useEffect(() => {
    const resStatus = data?.status;

    if (resStatus === "success") {
      setResponse("directors added");
    } else if (resStatus === "error") {
      setResponse("error directors");
    }
  }, [data]);

  return [responseDirectors, newDirerctor, isLoading] as const;
};
