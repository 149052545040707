import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Typography,
  OutlinedInput,
  Stack,
  Autocomplete,
  TextField,
} from "@mui/material";

import { UserDetailsForm } from "../../../interface/Common";
import { minmumBirthDate } from "../../../constants";

//CONSTANT
import { COUNTRIES } from "../../../constants";
import FileUploadComponent from "../../FileUpload";

// INPUT COMPONENT STYLING
const styles = {
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    pr: 1,
    mb: 3,
    flex: 1,
  },
  inputConStack: {
    display: "flex",
    flexDirection: "column",
    width: { xs: "100%" },
    pr: 1,
  },
};

type Props = {
  getFormValues: (values: UserDetailsForm) => void;
  getUserDetailsIcon: (value: boolean) => void;
};

export default function UserDetails({
  getFormValues,
  getUserDetailsIcon,
}: Props) {
  const [formValues, setFormValues] = useState<UserDetailsForm>({
    dob: minmumBirthDate(),
    occupation: "",
    po_box: "",
    physical_address: "",
    nationality: "KEN",
    id_number: "",
    user_national_id: null,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const fileUpload = (e: any) => {
    if (!("fileRes" in e)) {
      let file_response = e[Object.keys(e)[0]];
      if (file_response === null || file_response.size) {
        setFormValues({
          ...formValues,
          [Object.keys(e)[0]]: file_response,
        });
      }
    }
  };

  useEffect(() => {
    let status = false;
    if (
      formValues.dob &&
      formValues.occupation &&
      formValues.po_box &&
      formValues.physical_address &&
      formValues.id_number &&
      formValues.user_national_id
    ) {
      status = true;
    }

    getFormValues(formValues);
    getUserDetailsIcon(status);
  }, [formValues]);

  return (
    <>
      {/* Section 2 */}
      <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%" }}>
        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Occupation{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id="outlined-user-occupation"
            type="text"
            size="small"
            name="occupation"
            value={formValues.occupation}
            placeholder="Bank Manager"
            onChange={handleChange}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>
        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Nationality{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>

          <Autocomplete
            id="country_of_ops"
            options={COUNTRIES}
            size="small"
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
            value={COUNTRIES.find(
              (coun) => coun.code === formValues.nationality
            )}
            getOptionLabel={(option) => option.name}
            autoHighlight
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                setFormValues((Prev: UserDetailsForm | any) => ({
                  ...Prev,
                  nationality: newValue.code,
                }));
              }
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.flag?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.flag?.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
      </Stack>

      {/* Section 2 */}
      <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%" }}>
        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Physical Address{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id="outlined-company-physical-address"
            type="text"
            size="small"
            name="physical_address"
            placeholder="50, Ngong Road, Nairobi"
            onChange={handleChange}
            value={formValues.physical_address}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>

        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            Post Office Address{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id="outlined-company-po-box"
            type="text"
            size="small"
            name="po_box"
            placeholder="PO BOX 12345-00100 NAIROBI"
            value={formValues.po_box}
            onChange={handleChange}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>
      </Stack>

      {/* End Section 2 */}

      <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%" }}>
        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            ID/Passport Number{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id={`id_number`}
            value={formValues.id_number}
            type="text"
            size="small"
            placeholder={"12142132"}
            onChange={handleChange}
            name={`id_number`}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>

        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          <Typography component="label" fontSize="16px" lineHeight="2">
            DOB{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>

          <DatePicker
            label=""
            value={dayjs(formValues.dob)}
            defaultValue={dayjs(minmumBirthDate())}
            onChange={(newDate) => {
              const organizedDate = `${newDate?.year()}-${
                typeof newDate?.month() === "number" ? newDate?.month() + 1 : 1
              }-${newDate?.date()}`;

              setFormValues((Prev: UserDetailsForm | any) => ({
                ...Prev,
                dob: organizedDate,
              }));
            }}
            format="DD/MM/YYYY"
            sx={{
              borderRadius: "13px",
            }}
            slotProps={{
              textField: {
                size: "small",
                helperText: "DD/MM/YYYY",
                id: "outlined-dob",
                sx: {
                  borderRadius: "13px",
                  backgroundColor: "transparent",
                },
              },
            }}
            maxDate={dayjs(minmumBirthDate())}
          />
        </Box>
      </Stack>

      {/* Section 3 */}
      <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%" }}>
        <Box
          sx={{
            ...styles.inputContainer,
          }}
        >
          {" "}
          <Typography component="label" fontSize="16px" lineHeight="2">
            Upload ID/Passport{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <FileUploadComponent
            text="ID/Passport"
            nameValue="user_national_id"
            bring={fileUpload}
            customStyling={{ width: "100%" }}
            hideData={false}
            hideDelete={false}
            required
          />
        </Box>
      </Stack>
      {/* End Section 3 */}
    </>
  );
}
