import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";

import {
  Alert,
  Box,
  Button,
  FormGroup,
  Typography,
  Grid,
  Stack,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useNavigate } from "react-router-dom";
import { Companystyles } from "../../theme/Style";
import {
  CompanyDetailsInterface,
  CompanyValues,
  DirectorInterface,
  ShareHolderInterface,
  companyDocumentsInterface,
} from "../../interface/Common";

// RESOUCES
import { useCreateCompany } from "../../Hooks/createCompany";
import { useCreateDirectors } from "../../Hooks/createDirectors";
import CompanyDetails from "../../components/kyc/companyDetails";
import CompanyDocuments from "../../components/kyc/companyFiles";
import ShareHolderDetails from "../../components/kyc/shareHolders";
import CompanyDirectors from "../../components/kyc/directors";
import AuthContext from "../../context/AuthContext";
import { useUpdateUserConsent } from "../../Hooks/updateUserConsent";

type CompanyProps = {
  business_type: string;
};

export default function Company({ business_type }: CompanyProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { responseUser, updateUser } = useUpdateUserConsent();
  const [response, companyID, newCompany, isLoading] = useCreateCompany();
  const [responseDirectors, newDirerctor, isLoading2] = useCreateDirectors();
  const [companyDetailsIcon, setCompanyDetailsIconVar] = useState(false);
  const [companyDocumentsIcon, setCompanyDocumentsIconVar] = useState(false);

  //create a shareHolder Documents Icon state
  const [shareholderIcon, setShareHolderDocumentsIcon] = useState(false);

  //create a directors Icon state
  const [directorsIcon, setDirectorsIcon] = useState(false);

  //  const [countriesArray, callCountries] = useCountries();
  const date = new Date();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const sizeMatch = matches ? true : false;

  const todayDate = `${date.getFullYear()}-${
    date.getMonth() + 1 < 10 ? "0" : ""
  }${date.getMonth() + 1}-${date.getDate() - 1 < 10 ? "0" : ""}${
    date.getDate() - 1
  }`;

  const [formValues, setFormValues] = useState<CompanyValues | any>({
    kra_cert: null,
    cert_of_incorporation: null,
    cr_12: null,
    business_permit: null,
    beneficial_owner_form: null,
    directors_values: {},
    kra_pin: "",
    company_name: "",
    company_website: "",

    company_registration: "",
    company_telephone_number: "",
    company_reg_date: todayDate,

    billing_address: "",
    P_O_BOX: "",

    industry: business_type,
    number_of_employees: "0-10",
    country_of_ops: 0,
    nature_of_business: "",
    shareholderObj: {},
  });

  const [consent, setConsent] = useState({
    marketing_consent: false,
    user_consent: false,
  });

  const [mess, setMess] = useState("");
  const [btnMess, setBtnMess] = useState("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isDirectorsFormat = checkDirectors();
    if (isDirectorsFormat) {
      updateUser(consent);
    } else {
      window.scrollTo(0, 0);
      setMess(
        "There is a problem with the inputed directors,try again or contact us for support"
      );
    }
  };

  //check Directors
  const checkDirectors = () => {
    let isFormatGood = true;

    for (const director of Object.keys(formValues.directors_values)) {
      const directorData = formValues.directors_values[director];
      if (
        directorData.first_name === "" &&
        directorData.last_name === "" &&
        directorData.director_id_copy === null
      ) {
        isFormatGood = false;
      }
    }
    return isFormatGood;
  };

  //check for user consent
  useEffect(() => {
    if (responseUser.message === "User consent updated") {
      newCompany(formValues);
    } else if (
      responseUser.message === "Error updating user contact us for help"
    ) {
      setMess(
        "Error updating user consent,try again or contact us for support"
      );
    }
  }, [responseUser]);

  //Checks for uploaded company
  useEffect(() => {
    if (response === "company added") {
      newDirerctor(formValues, companyID);
    } else if (response.length > 0 && response !== "company added") {
      window.scrollTo(0, 0);
      setMess(
        response ||
          "Company could not be created,try again or contact us for support"
      );
    }
  }, [response]);

  //Checks for uploaded directors
  useEffect(() => {
    if (responseDirectors === "directors added") {
      // authctx.logout();
      // navigate("/verification");
      authCtx.setUser({ ...authCtx.userDetails, hasCompany: true });
      navigate("/kyc-documents");
    } else if (responseDirectors === "error directors") {
      window.scrollTo(0, 0);
      setMess(
        "There was a problem with uploading directors,try again or contact us for support"
      );
    }
  }, [responseDirectors]);

  const isValid = useMemo(() => {
    if (!companyDetailsIcon) {
      setBtnMess(
        "Please fill all fields in the company detail section correctly."
      );
      return false;
    }

    if (!companyDocumentsIcon) {
      setBtnMess(
        "Please upload all the requried documents on the document section."
      );
      return false;
    }

    if (!shareholderIcon) {
      setBtnMess(
        "Please fill all fields in the shareholder section correctly."
      );
      return false;
    }

    if (!directorsIcon) {
      setBtnMess("Please fill all fields in the directors section correctly.");
      return false;
    }

    return true;
  }, [
    companyDetailsIcon,
    companyDocumentsIcon,
    shareholderIcon,
    directorsIcon,
  ]);

  const setCompanyDetails = useCallback((e: CompanyDetailsInterface) => {
    //get each item in the object and add it to formvalues
    setFormValues((prev: CompanyValues) => {
      return {
        ...prev,
        ...e,
      };
    });
  }, []);

  const setCompanyDocuments = useCallback((e: companyDocumentsInterface) => {
    setFormValues((prev: CompanyValues) => {
      return {
        ...prev,
        ...e,
      };
    });
  }, []);

  const setShareHolderDocuments = useCallback((e: ShareHolderInterface) => {
    setFormValues((prev: CompanyValues) => {
      return {
        ...prev,
        ...e,
      };
    });
  }, []);

  const setDirectors = useCallback((e: DirectorInterface) => {
    setFormValues((prev: CompanyValues) => {
      return {
        ...prev,
        ...e,
      };
    });
  }, []);

  return (
    <Grid container py={2}>
      {mess ? (
        <Grid item xs={12}>
          <Alert severity="error">{mess}</Alert>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <Accordion
            defaultExpanded={true}
            style={{ backgroundColor: "transparent" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: `#425FB4` }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: `#F5F5F5` }}
            >
              {companyDetailsIcon ? (
                <CheckCircleIcon
                  sx={{ color: `#425FB4`, marginRight: 2 }}
                  fontSize="small"
                  data-cy="companyCircle"
                />
              ) : null}
              <Typography variant="body2" sx={{ color: `#425FB4` }}>
                Company details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CompanyDetails
                getCompanyDetailsIcon={setCompanyDetailsIconVar}
                getFormValues={setCompanyDetails}
                industry={business_type}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            defaultExpanded={true}
            style={{ backgroundColor: "transparent" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: `#425FB4` }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: `#F5F5F5` }}
            >
              {companyDocumentsIcon ? (
                <CheckCircleIcon
                  sx={{ color: `#425FB4`, marginRight: 2 }}
                  fontSize="small"
                  data-cy={"companyDocumentsCircle"}
                />
              ) : null}
              <Typography variant="body2" sx={{ color: `#425FB4` }}>
                Company documents
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup row={sizeMatch} sx={{ width: "100%" }}>
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Typography fontSize="14px" mb={2}>
                    You can upload the respective only documents in PDF format.
                  </Typography>

                  <CompanyDocuments
                    getCompanyDocuments={setCompanyDocuments}
                    getCompanyDocumentsStatus={setCompanyDocumentsIconVar}
                  />
                </Stack>
              </FormGroup>
            </AccordionDetails>
          </Accordion>

          <Accordion
            defaultExpanded={true}
            style={{ backgroundColor: "transparent" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: `#425FB4` }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: "#F5F5F5" }}
            >
              {shareholderIcon ? (
                <CheckCircleIcon
                  sx={{ color: `#425FB4`, marginRight: 2 }}
                  fontSize="small"
                  data-cy="shareHolderCircle"
                />
              ) : null}
              <Typography variant="body2" sx={{ color: `#425FB4` }}>
                Shareholder details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ShareHolderDetails
                getShareHolders={setShareHolderDocuments}
                getshareHolderIconStatus={setShareHolderDocumentsIcon}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            defaultExpanded={true}
            style={{ backgroundColor: "transparent" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: `#425FB4` }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: "#F5F5F5" }}
            >
              {directorsIcon ? (
                <CheckCircleIcon
                  sx={{ color: `#425FB4`, marginRight: 2 }}
                  fontSize="small"
                />
              ) : null}
              <Typography variant="body2" sx={{ color: `#425FB4` }}>
                Directors
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CompanyDirectors
                getDirectors={setDirectors}
                getDirectorsIconStatus={setDirectorsIcon}
              />
            </AccordionDetails>
          </Accordion>

          <Typography mt={2} mb={1} fontSize="14px">
            These documents are required for KYC by Bonto Kenya Money Transfer
            Limited to fulfill their obligations towards the Central Bank of
            Kenya. By clicking “Submit”, you agree for Bonto Limited to share
            this data with Bonto Kenya Money Transfer Limited.
          </Typography>

          <Box
            sx={{
              paddingY: 2,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="consent"
                  onChange={(e) =>
                    setConsent({
                      ...consent,
                      user_consent: e.target.checked,
                    })
                  }
                />
              }
              label={
                <Typography fontSize="14px">
                  I confirm that the information provided is accurate and true.
                  By checking this box, I consent to the use of my data by Bonto
                  Kenya Money Transfer Limited for transaction purposes.
                </Typography>
              }
            />
          </Box>

          <Box
            sx={{
              paddingY: 2,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="marketing_consent"
                  onChange={(e) =>
                    setConsent({
                      ...consent,
                      marketing_consent: e.target.checked,
                    })
                  }
                />
              }
              label={
                <Typography fontSize="14px">
                  I agree to receive occasional marketing messages containing
                  information about the Company's products and services. I
                  understand that I can opt out of these communications at any
                  time by contacting Bonto Kenya Money Transfer Limited
                  directly.
                </Typography>
              }
            />
          </Box>

          <FormGroup row style={Companystyles.groupsB}>
            <Button
              variant="contained"
              type="submit"
              sx={{ width: "100%", mt: 2 }}
              disabled={
                !isValid || isLoading || isLoading2 || !consent.user_consent
              }
            >
              {isLoading || isLoading2 ? (
                <Stack sx={{ color: "primary" }}>
                  <CircularProgress size={20} color="inherit" />
                </Stack>
              ) : (
                "   Submit"
              )}
            </Button>

            {!isValid && (
              <Alert sx={{ width: "100%", mt: 2 }} severity="error">
                Why is the button disabled? {btnMess}
              </Alert>
            )}
          </FormGroup>
        </form>
      </Grid>
    </Grid>
  );
}
