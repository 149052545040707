import React, { useState } from "react";
import { useFetchQuery } from "../Hooks/reactQuery";
import AuthContext from "./AuthContext";

type Props = {
  children: JSX.Element;
};

export type CompanyDetailsType = {
  industry: string;
  isNGO: boolean;
  isCompany: boolean;
};

export const companyDetails: CompanyDetailsType = {
  industry: "",
  isNGO: false,
  isCompany: false,
};

const OrganizationTypeNonNGO = [
  "Business name/Sole Proprietorship",
  "Partnership Business",
  "Private Limited Company",
  "Company Limited by Guarantee",
  "Public Limited Company",
  "Foreign Company – Local Office",
  "Unlimited Company",
];

const CompanyContext = React.createContext(companyDetails);

export const CompanyContextProvider = ({ children }: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { data, refetch } = useFetchQuery(
    "auth/company-details/",
    "company-details",
    false,
    false,
    authCtx.companyDetails.tokenAccess
  );

  const [company, setCompany] = useState<CompanyDetailsType>(companyDetails);

  React.useEffect(() => {
    if (
      data === undefined &&
      authCtx.userDetails.hasCompany &&
      authCtx.companyDetails.documentsVerified
    ) {
      refetch();
    } else if (data !== undefined) {
      const resStatus = data?.status;

      if (resStatus === "success") {
        const resData = data?.data;
        if (resData?.company?.uuid) {
          if (resData?.company?.industry === "NGO") {
            setCompany({
              industry: resData.company.industry,
              isNGO: true,
              isCompany: false,
            });
          } else if (
            OrganizationTypeNonNGO.includes(resData?.company?.industry)
          ) {
            setCompany({
              industry: resData.company.industry,
              isNGO: false,
              isCompany: true,
            });
          }
        }
      }
    }
  }, [authCtx, data]);

  return (
    <CompanyContext.Provider
      value={{
        industry: company.industry,
        isNGO: company.isNGO,
        isCompany: company.isCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyContext;
