import { useState, useEffect } from "react";

import { Box, Modal, Stack, Typography } from "@mui/material";
import FileUploadComponent from "../../FileUpload";
import { NGODocumentsInterface } from "../../../interface/NGO";

type Props = {
  getNGODocuments: (e: NGODocumentsInterface) => void;
  getNGODocumentsStatus: (e: boolean) => void;
};

export default function NGODocuments({
  getNGODocuments,
  getNGODocumentsStatus,
}: Props) {
  const [formValues, setFormValues] = useState<NGODocumentsInterface>({
    certificate_of_registration: null,
    constitution_rules_memo_articles: null,
    kra_cert: null,
    proof_of_address: null,
    resolution_to_transact: null,
  });

  const fileUpload = (e: any) => {
    if ("fileRes" in e) return;
    console.log(e);
    setFormValues({
      ...formValues,
      [Object.keys(e)[0]]: e[Object.keys(e)[0]],
    });
  };

  useEffect(() => {
    let status = false;
    if (
      formValues.certificate_of_registration &&
      formValues.constitution_rules_memo_articles &&
      formValues.kra_cert &&
      formValues.resolution_to_transact
    ) {
      status = true;
    }

    getNGODocuments(formValues);
    getNGODocumentsStatus(status);
  }, [formValues, getNGODocumentsStatus, getNGODocuments]);

  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        sx={{ width: "100%" }}
      >
        <FileUploadComponent
          text="Certificate of Registration"
          nameValue="certificate_of_registration"
          bring={fileUpload}
          customStyling={{ width: "50%" }}
          hideData={false}
          hideDelete={true}
          multiple={false}
          required
        />
        <FileUploadComponent
          text="Constitution or Memorandum and Articles of Association"
          nameValue="constitution_rules_memo_articles"
          bring={fileUpload}
          customStyling={{ width: "50%" }}
          hideData={false}
          hideDelete={true}
          multiple={false}
          required
        />
      </Stack>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        sx={{ width: "100%" }}
      >
        <FileUploadComponent
          text="PIN Certificate/ Tax Exemption Certificate"
          nameValue="kra_cert"
          bring={fileUpload}
          customStyling={{ width: "50%" }}
          hideData={false}
          hideDelete={true}
          multiple={false}
          required
        />
        <FileUploadComponent
          text="Proof of address"
          nameValue="proof_of_address"
          bring={fileUpload}
          customStyling={{ width: "50%" }}
          hideData={false}
          hideDelete={true}
          multiple={false}
        />
      </Stack>
      <Stack direction={{ xs: "column" }} spacing={1} sx={{ width: "100%" }}>
        <FileUploadComponent
          text="Board resolution to transact with Bonto Kenya Money Transfer Limited"
          nameValue="resolution_to_transact"
          bring={fileUpload}
          customStyling={{ width: "100%" }}
          hideData={false}
          hideDelete={true}
          multiple={false}
          required
        />
      </Stack>
    </>
  );
}
